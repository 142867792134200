import IframeContainer from "./IframeContainer";

const renderYoutube = (uri) => {
  return (
    <IframeContainer>
      <iframe
        src={uri}
        allow="accelerometer; encrypted-media; gyroscope; picture-in-picture"
        frameBorder="0"
        allowFullScreen
      ></iframe>
    </IframeContainer>
  );
};

export const youtubeRenderer = {
  renderYoutube,
};
