import React, { useState } from "react";
import { styles } from "./BolloComponent.style";
import BolloCalculator from "./BolloCalculator";
import { TabToggleGroup } from "../../elements/TabToggleGroup";
import { TabToggle } from "../../elements/TabToggleGroup/components/TabToggle";
import { InputStatus } from "./BolloCalculator/VinCheckInput";

export interface Translations {
  regionText: string;
  regionPlaceholder: string;
  euroNormText: string;
  enginePowerText: string;
  enginePowerPlaceholder: string;
  euroNormPlaceholder: string;
  calculateText: string;
  summaryText: string;
  errorMessage: string;
  vinCheckErrorMessage: string;
  vinCheckLabelText: string;
  tabTexts: { tab1: string; tab2: string };
}
export interface ModuleData {
  heading: string;
  subtitle: string;
  information: string;
  isHeadingH2?: boolean;
  hideVinSearchBollo: boolean;
}

export interface VinSearchData {
  enginePowerKW: number;
  euEmissionStandard: string;
  motorPowerKW: number;
}

export interface DataLayer {
  errorEvent: (errorMessage: string, isVinSearch: boolean) => void;
  successEvent: (isVinSearch: boolean) => void;
  switchTabEvent?: (isVinSearch: boolean) => void;
}

export interface BolloComponentProps {
  moduleData: ModuleData;
  translations: Translations;
  inputStatus: string;
  vinSearchData: VinSearchData;
  dataLayer: DataLayer;
  setInputStatus: (inputStatus: InputStatus) => void;
  handleVinCheck: (vinNumber: string) => Promise<void>;
}

export const BolloComponent: React.FC<BolloComponentProps> = ({
  moduleData,
  translations,
  inputStatus,
  vinSearchData,
  dataLayer,
  setInputStatus,
  handleVinCheck,
}) => {
  const [activeTabId, setActiveTabId] = useState("0");

  const switchTab = (id: string) => {
    setActiveTabId(id);
    dataLayer.switchTabEvent(id === "0");
  };

  return (
    <div css={styles.root()}>
      <div css={styles.textWrapper()}>
        {moduleData.isHeadingH2 ? <h2>{moduleData.heading}</h2> : <h1>{moduleData.heading}</h1>}
        <div css={styles.subtitle()}>{moduleData.subtitle}</div>
        <div css={styles.information()}>{moduleData.information}</div>
      </div>
      <div css={styles.bolloWrapper()}>
        {!moduleData.hideVinSearchBollo && (
          <div css={styles.tabWrapper()}>
            <TabToggleGroup>
              <TabToggle
                title={translations.tabTexts.tab1}
                id="0"
                activeTabId={activeTabId}
                setActiveTabId={switchTab}
              />
              <TabToggle
                title={translations.tabTexts.tab2}
                id="1"
                activeTabId={activeTabId}
                setActiveTabId={switchTab}
              />
            </TabToggleGroup>
          </div>
        )}
        <BolloCalculator
          setInputStatus={setInputStatus}
          translations={translations}
          inputStatus={inputStatus}
          vinCheckBollo={!moduleData.hideVinSearchBollo ? activeTabId === "0" : activeTabId === "1"}
          handleVinCheck={handleVinCheck}
          dataLayer={dataLayer}
          vinSearchData={vinSearchData}
        ></BolloCalculator>
      </div>
      <div css={styles.informationMobile()}>{moduleData.information}</div>
    </div>
  );
};
