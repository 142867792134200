import { mediaQuery, spacing } from "@carfax/web-ui/core";
import { css } from "app";

export const styles = {
  modalHeading: () =>
    css({
      textAlign: "center",
      [mediaQuery.desktopOrLarger]: {
        marginTop: spacing.baseUnitMobile1,
      },
    }),
  modalTextWrapper: () =>
    css({
      maxWidth: "528px",
      margin: "0 auto",
      h1: {
        margin: `0 auto ${spacing.baseUnitMobile2}`,
        [mediaQuery.desktopOrLarger]: {
          margin: `0 auto ${spacing.baseUnitMobile3}`,
        },
      },
    }),
};
