import { ReactNode } from "react";
import { vimeoRenderer } from "./VimeoRenderer";
import { youtubeRenderer } from "./YoutubeRenderer";
import { linkOriginResolver } from "../../../utils/contentful/link-origin-resolver";
import { linkRenderer } from "./LinkRenderer";

const renderHyperlink = (node, children: ReactNode) => {
  const uri = node.data.uri;

  if (linkOriginResolver.linkIsFromVimeo(uri)) {
    return vimeoRenderer.renderVimeo(uri);
  } else if (linkOriginResolver.linkIsFromYoutube(uri)) {
    return youtubeRenderer.renderYoutube(uri);
  } else if (linkOriginResolver.linkIsExternal(uri)) {
    return linkRenderer.renderExternalLink(uri, children);
  } else {
    return linkRenderer.renderInternalLink(uri, children);
  }
};

export const inlinesHandler = {
  renderHyperlink,
};
