import { css } from "@emotion/react";
import { colors, fontSizes, letterSpacings, lineHeights, mediaQuery, spacing } from "../../core/styles";

export const styles = {
  contentWrapper: () =>
    css({
      display: "flex",
      width: "100%",
      flexDirection: "column",
      gap: `0 ${spacing.baseUnitMobile3}`,
      justifyContent: "center",
      [mediaQuery.desktopOrLarger]: {
        display: "flex",
        flexDirection: "row",
      },
    }),
  textWrapper: () =>
    css({
      display: "flex",
      flexDirection: "column",
      textAlign: "center",
      [mediaQuery.desktopOrLarger]: {
        maxWidth: "528px",
      },
      maxWidth: "100%",
      alignSelf: "center",
    }),
  titleWrapper: () =>
    css({
      alignSelf: "center",
      maxWidth: "initial",
      h2: {
        textAlign: "center",
        marginBottom: spacing.baseUnitMobile3,
      },
    }),
  subtitleWrapper: () =>
    css({
      alignSelf: "center",
      color: colors.headlineGrey.hex,
      margin: `${spacing.baseUnitMobile3} 0`,
      [mediaQuery.desktopOrLarger]: {
        margin: `0 0 ${spacing.baseUnitMobile3}`,
      },
      maxWidth: "initial",
      fontSize: fontSizes.body.horse,
      letterSpacing: letterSpacings.body.horse,
      lineHeight: lineHeights.body.horse,
    }),
  imageWrapper: () =>
    css({
      alignSelf: "center",
      [mediaQuery.desktopOrLarger]: {
        maxWidth: "528px",
      },
      maxWidth: "640px",
      width: "100%",
      img: {
        width: "100%",
      },
    }),
};
