export type State = {
  regionHasError: boolean;
  regionVinSearchHasError: boolean;
  enginePowerHasError: boolean;
  euroNormHasError: boolean;
  vinCheckHasError: boolean;
  validationPerformed: boolean;
};

export type Action =
  | { type: "SET_REGION_ERROR"; payload: boolean }
  | { type: "SET_REGION_VIN_SEARCH_ERROR"; payload: boolean }
  | { type: "SET_ENGINE_POWER_ERROR"; payload: boolean }
  | { type: "SET_EURO_NORM_ERROR"; payload: boolean }
  | { type: "SET_VIN_CHECK_ERROR"; payload: boolean }
  | { type: "SET_VALIDATION_PERFORMED"; payload: boolean }
  | { type: "RESET_ERRORS" };

export const initialState: State = {
  regionHasError: false,
  regionVinSearchHasError: false,
  enginePowerHasError: false,
  euroNormHasError: false,
  vinCheckHasError: false,
  validationPerformed: false,
};

export const reducer = (state: State, action: Action): State => {
  switch (action.type) {
    case "SET_REGION_ERROR":
      return { ...state, regionHasError: action.payload };
    case "SET_REGION_VIN_SEARCH_ERROR":
      return { ...state, regionVinSearchHasError: action.payload };
    case "SET_ENGINE_POWER_ERROR":
      return { ...state, enginePowerHasError: action.payload };
    case "SET_EURO_NORM_ERROR":
      return { ...state, euroNormHasError: action.payload };
    case "SET_VIN_CHECK_ERROR":
      return { ...state, vinCheckHasError: action.payload };
    case "SET_VALIDATION_PERFORMED":
      return { ...state, validationPerformed: action.payload };
    case "RESET_ERRORS":
      return initialState;
    default:
      return state;
  }
};
