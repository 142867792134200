import { useEffect, useState } from "react";

const useDelay = (value, time) => {
  const [delay, setDelay] = useState(value);
  const [hasRendered, setHasRendered] = useState(false);

  useEffect(() => {
    if (!hasRendered) {
      setHasRendered(true);
    } else {
      const timeout = setTimeout(() => {
        setDelay(!delay);
      }, time);

      return () => clearTimeout(timeout);
    }
    return undefined;
  }, [value]);

  return delay;
};

export { useDelay };
