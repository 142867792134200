const renderImage = (node) => {
  const { title, file } = node.data.target.fields;
  return <img src={file?.url} title={title} alt={title} />;
};

const renderPdfLink = (node) => {
  const { title, file } = node.data.target.fields;
  return <a href={file?.url}>{title}</a>;
};

const renderAsset = (node) => {
  const { file } = node.data.target.fields;
  if (file.contentType === "application/pdf") {
    return renderPdfLink(node);
  } else {
    return renderImage(node);
  }
};

export const assetRenderer = {
  renderImage,
  renderPdfLink,
  renderAsset,
};
