import { Link } from "../../../i18n/components";

const renderExternalLink = (uri, children) => (
  <a href={uri} target="blank" title={getStringFromObject(children)}>
    {children}
  </a>
);

const renderInternalLink = (uri, children) => {
  return (
    <Link href={uri} passHref title={getStringFromObject(children)}>
      {children}
    </Link>
  );
};

const getStringFromObject = (children) => {
  if (typeof children?.[0]?.props?.children === "string") {
    return children[0].props.children;
  }
  return "";
};

export const linkRenderer = {
  renderExternalLink,
  renderInternalLink,
};
