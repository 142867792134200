import React, { useMemo } from "react";
import {
  ModuleAccordionCards,
  ModuleBanner,
  ModuleBolloCalculator,
  ModuleCampaignBanner,
  ModuleDeviceCarousel,
  ModuleGallery,
  ModuleGeneralVinSearch,
  ModuleGoogleReviews,
  ModuleHRContactArea,
  ModuleLandingPageVoucherEntry,
  ModulePackageWithoutVin,
  ModulePartnerLogos,
  ModuleReportFeatures,
  ModuleSampleVhr,
  ModuleTextWithImage,
  ModuleUsps,
  PageModularModuleWrapper,
} from "../../../contentful/PageModularModule";
import SampleVhr from "domain/CommonModules/SampleVhr";
import { ModuleContentType } from "../../../contentful/types/content-type";
import dynamic from "next/dynamic";
import GeneralVinSearch from "../GeneralVinSearch";
import LandingPageVoucherEntry from "../LandingPageVoucherEntry";
import BolloCalculatorModule from "../BolloCalculator/BolloCalculatorModule";

const ReportFeatures = dynamic(() => import("domain/CommonModules/ReportFeatures"));
const PromotionBanner = dynamic(() => import("domain/CommonModules/PromotionBanner"));
const PartnerLogos = dynamic(() => import("domain/CommonModules/PartnerLogos"), { ssr: false });
const TextImage = dynamic(() => import("domain/CommonModules/TextImage"));
const Gallery = dynamic(() => import("domain/CommonModules/Gallery"), { ssr: false });
const HrContactArea = dynamic(() => import("domain/CommonModules/HrContactArea"));
const Usps = dynamic(() => import("domain/CommonModules/Usps"));
const AccordionCards = dynamic(() => import("domain/CommonModules/AccordionCards"));
const DeviceCarousel = dynamic(() => import("domain/CommonModules/DeviceCarousel"));
const CampaignBanner = dynamic(() => import("domain/CommonModules/CampaignBanner"));
const PackageWithoutVinModule = dynamic(() => import("domain/CommonModules/PackageWithoutVinModule"));
const GoogleReviewsCommonModule = dynamic(() => import("domain/CommonModules/GoogleReviews"));

interface Props {
  moduleWrapper: PageModularModuleWrapper;
  splitterEnabled: boolean;
}

const ModularPageComponentWrapper: React.FC<Props> = ({ moduleWrapper, splitterEnabled }) => {
  return useMemo(() => {
    switch (moduleWrapper.contentType) {
      case ModuleContentType.ModuleSampleVhr:
        return <SampleVhr module={moduleWrapper.module as ModuleSampleVhr} splitterEnabled={splitterEnabled} />;
      case ModuleContentType.ModuleReportFeatures:
        return <ReportFeatures module={moduleWrapper.module as ModuleReportFeatures} />;
      case ModuleContentType.ModuleBanner:
        return <PromotionBanner module={moduleWrapper.module as ModuleBanner} />;
      case ModuleContentType.ModulePartnerLogos:
        return <PartnerLogos module={moduleWrapper.module as ModulePartnerLogos} />;
      case ModuleContentType.ModuleTextWithImage:
        return <TextImage module={moduleWrapper.module as ModuleTextWithImage} />;
      case ModuleContentType.ModuleGallery:
        return <Gallery module={moduleWrapper.module as ModuleGallery} />;
      case ModuleContentType.ModuleHRContactArea:
        return <HrContactArea module={moduleWrapper.module as ModuleHRContactArea} />;
      case ModuleContentType.ModuleUsps:
        return <Usps module={moduleWrapper.module as ModuleUsps} />;
      case ModuleContentType.ModuleAccordionCards:
        return <AccordionCards module={moduleWrapper.module as ModuleAccordionCards} />;
      case ModuleContentType.ModuleDeviceCarousel:
        return <DeviceCarousel module={moduleWrapper.module as ModuleDeviceCarousel} />;
      case ModuleContentType.ModuleCampaignBanner:
        return <CampaignBanner module={moduleWrapper.module as ModuleCampaignBanner} />;
      case ModuleContentType.ModulePackageWithoutVin:
        return <PackageWithoutVinModule module={moduleWrapper.module as ModulePackageWithoutVin} />;
      case ModuleContentType.ModuleGeneralVinSearch:
        return <GeneralVinSearch module={moduleWrapper.module as ModuleGeneralVinSearch} />;
      case ModuleContentType.ModuleGoogleReviews:
        return <GoogleReviewsCommonModule module={moduleWrapper.module as ModuleGoogleReviews} />;
      case ModuleContentType.ModuleLandingPageVoucherEntry:
        return <LandingPageVoucherEntry module={moduleWrapper.module as ModuleLandingPageVoucherEntry} />;
      case ModuleContentType.ModuleBolloCalculator:
        return <BolloCalculatorModule module={moduleWrapper.module as ModuleBolloCalculator} />;
      default:
        return null;
    }
  }, [moduleWrapper.contentType, moduleWrapper.module, splitterEnabled]);
};

export default ModularPageComponentWrapper;
