import { IAlert, TAlertVariant } from "types/stores";
import AlertStore from "stores/alert.store";

class AlertService {
  public showAlert(message: string, variant: TAlertVariant = "error", closable = true) {
    const thankYouAlert: IAlert = {
      message,
      variant,
      closable,
    };
    AlertStore.replace([thankYouAlert]);
  }

  public showAlertToTranslate(
    messageToTranslate: string,
    variant: TAlertVariant = "error",
    closable = true,
    paymentMethod?: string,
    product?: string
  ) {
    const thankYouAlert: IAlert = {
      messageToTranslate,
      variant,
      closable,
      paymentMethod,
      product,
    };
    AlertStore.replace([thankYouAlert]);
  }
}

export default new AlertService();
