import { css } from "@emotion/react";
import { spacing, colors, mediaQuery, lineHeights, letterSpacings, fontSizes } from "../../../../core/styles";

export const styles = {
  description: () =>
    css({
      display: "flex",
      flexDirection: "column",
      maxWidth: "552px",
      margin: `${spacing.baseUnitMobile4} auto`,
      [mediaQuery.desktopOrLarger]: {
        flexDirection: "row-reverse",
        margin: `${spacing.baseUnitMobile3} auto`,
      },
    }),
  details: () =>
    css({
      flexGrow: 1,
      marginBottom: spacing.baseUnitMobile1,
      [mediaQuery.desktopOrLarger]: {
        marginBottom: 0,
      },
    }),
  carImage: () =>
    css({
      margin: "0 auto",
      width: "196px",
      height: "147px",
      border: `1px solid ${colors.disabledGrey.hex}`,
      [mediaQuery.desktopOrLarger]: {
        width: "184px",
        height: "138px",
      },
      img: {
        objectFit: "cover",
        objectPosition: "center center",
        width: "100%",
        height: "100%",
        display: "block",
      },
    }),
  detail: () =>
    css({
      paddingBottom: spacing.baseUnitMobile1,
      marginBottom: spacing.baseUnitMobile1,
      borderBottom: `1px solid ${colors.disabledGrey.hex}`,
      lineHeight: lineHeights.body.horse,
      letterSpacing: letterSpacings.body.horse,
      [mediaQuery.desktopOrLarger]: {
        marginLeft: spacing.baseUnitMobile3,
      },
    }),
  name: () =>
    css({
      margin: "0",
      fontWeight: 700,
      letterSpacings: letterSpacings.heading.H4.mobile,
      fontSize: fontSizes.heading.H4.mobile,
      lineHeight: lineHeights.heading.H4.mobile,
      [mediaQuery.desktopOrLarger]: {
        letterSpacing: letterSpacings.heading.H4.desktop,
        fontSize: fontSizes.heading.H4.desktop,
        lineHeight: lineHeights.heading.H4.desktop,
      },
    }),
};
