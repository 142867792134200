import { css } from "app";
import { ReactNode } from "react";

interface Props {
  children: ReactNode;
}

const styles = {
  iframeWrapper: () =>
    css({
      paddingBottom: "56.25%",
      position: "relative",
      display: "block",
      width: "100%",
      iframe: {
        height: "100%",
        width: "100%",
        position: "absolute",
        top: "0",
        left: "0",
      },
    }),
};

const IframeContainer: React.FC<Props> = (props) => {
  const { children } = props;

  return (
    <div css={styles.iframeWrapper()}>
      <>{children}</>
    </div>
  );
};

export default IframeContainer;
