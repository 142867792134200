import { useTranslation } from "app";
import React from "react";
import { styles } from "./WhereIsVinModalContent.style";

const HowDoesItWorkModalContent: React.FC = () => {
  const { t } = useTranslation(["page-index"]);

  return (
    <>
      <div css={styles.modalTextWrapper()}>
        <h1 css={styles.modalHeading()}>{t("howDoesItWorksModal.title")}</h1>
        <p>
          <strong>{t("howDoesItWorksModal.paragraph1.title")}</strong>
          <br /> {t("howDoesItWorksModal.paragraph1.content")}
        </p>
        <p>
          <strong>{t("howDoesItWorksModal.paragraph2.title")}</strong>
          <br /> {t("howDoesItWorksModal.paragraph2.content")}
        </p>
        <p css={styles.advantagesHeader()}>
          <strong>{t("howDoesItWorksModal.advantages.title")}</strong>
        </p>
        <ul css={styles.advantagesList()}>
          <li>{t("howDoesItWorksModal.advantages.advantage1")}</li>
          <li>{t("howDoesItWorksModal.advantages.advantage2")}</li>
          <li>{t("howDoesItWorksModal.advantages.advantage3")}</li>
          <li>{t("howDoesItWorksModal.advantages.advantage4")}</li>
        </ul>
      </div>
    </>
  );
};

export default HowDoesItWorkModalContent;
