import { useState } from "react";
import { withRecaptcha } from "domain/Recaptcha/withRecaptcha";
import { ApiConfig } from "utils/backend-api/api-config";
import { PaymentsApi } from "utils/backend-api/payment/payments-api";
import RecaptchaActions from "domain/Recaptcha/actions";
import BackendApiError from "utils/backend-api/backend-api-error";
import { EHttpStatus } from "utils/backend-api/error-types";
import { useStores } from "hooks";
import { VoucherCode } from "stores/checkout.store";
import { Log, buildLogEntityWithoutRequest, formatMessage } from "utils/log";
import { VOUCHER_RECAPTCHA_ID } from "../VoucherField";

export const useCheckVoucherCode = () => {
  const [voucherValues, setVoucherValues] = useState({});
  const [voucherErrorMsg, setVoucherErrorMsg] = useState("");
  const [validated, setValidated] = useState(false);
  const [voucherCheckPending, setVoucherCheckPending] = useState(false);

  const { checkoutStore } = useStores();

  const [loader, setLoader] = useState(false);
  const { voucherCode } = voucherValues as VoucherCode;

  const checkVoucher = async (voucherCode: string, packageName: string = "") => {
    setVoucherErrorMsg(null);
    setValidated(false);

    if (!voucherCode) {
      setVoucherErrorMsg("formErrors.voucher.emptyVoucher");
      return;
    }
    setVoucherCheckPending(true);
    setLoader(true);

    const paymentApi = new PaymentsApi(ApiConfig.buildBackendUrlForClient());

    try {
      const values = (await withRecaptcha({
        action: RecaptchaActions.voucher,
        captchaId: VOUCHER_RECAPTCHA_ID,
      })((token) => paymentApi.fetchVoucherCodeWithPackageName(voucherCode, packageName, token))) as VoucherCode;
      setVoucherValues(values);
      checkoutStore.setVoucherFromQuery({
        voucherCode: values.voucherCode,
        discountPercent: values.discountPercent,
        isDiscounted: true,
      });
      setValidated(true);
    } catch (error) {
      if (error instanceof BackendApiError) {
        if (error.status === EHttpStatus.Forbidden) {
          setVoucherErrorMsg("formErrors.voucher.recaptcha");
        }
        if (error.status === EHttpStatus.NotFound) {
          setVoucherErrorMsg(error.msg);
          Log.error(buildLogEntityWithoutRequest(formatMessage("not found voucher", error), error));
        } else {
          setVoucherErrorMsg(error.msg);
        }
      }
    } finally {
      setVoucherCheckPending(false);
    }
  };

  return { voucherCode, loader, checkVoucher, voucherErrorMsg, validated, voucherCheckPending };
};
