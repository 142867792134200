import React from "react";
import { styles } from "./CarSubmission.styles";
import { ContentAfterRecordCheck, IContentAfterRecordCheckProps } from "./components/ContentAfterRecordCheck";
import { CarDetails, ICarDetailsProps } from "./components/CarDetails";

export interface ICarSubmissionProps extends Partial<ICarDetailsProps>, Partial<IContentAfterRecordCheckProps> {
  title: string;
  partnerLogo?: string;
  showContent: boolean;
  showDetails?: boolean;
  children?: React.ReactNode;
}

export function CarSubmission({
  title,
  partnerLogo,
  carImage,
  enterVinMessage,
  afterVinCheck,
  details,
  vhrFound,
  onCtaClick,
  ctaLink,
  ctaText,
  VinSearchComponent,
  showContent,
  isVinValid,
  showDetails = true,
  children,
}: ICarSubmissionProps) {
  return (
    <div css={styles.root}>
      {partnerLogo && <img src={partnerLogo} css={styles.partnerLogo} alt="" title="" />}
      {showContent && <h1>{title}</h1>}

      {showDetails && <CarDetails details={details} carImage={carImage} />}

      {children}

      {showContent && (
        <ContentAfterRecordCheck
          enterVinMessage={enterVinMessage}
          afterVinCheck={afterVinCheck}
          vhrFound={vhrFound}
          isVinValid={isVinValid}
          onCtaClick={onCtaClick}
          ctaLink={ctaLink}
          ctaText={ctaText}
          VinSearchComponent={VinSearchComponent}
        />
      )}
    </div>
  );
}
