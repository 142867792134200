import { colors } from "../../../core";

export enum ThemeName {
  DEFAULT = "Default",
  HOMEPAGE = "Homepage",
  BOTTOM_BLUE = "Bottom Blue",
  DEFAULT_OFFSET_2 = "Default with Offset",
  BOTTOM_BLUE_OFFSET_2 = "Bottom Blue with Offset",
  NO_VIN_HEADER = "No Vin Header",
}

export interface ThemeStyles {
  primaryColor: string;
  secondaryColor: string;
  firstModuleColor?: string;
  lastModuleColor?: string;
  downDiagonalOffset: 1 | 2;
}

export const useTheme = (themeName?: ThemeName): ThemeStyles => {
  switch (themeName) {
    case ThemeName.DEFAULT:
      return {
        primaryColor: colors.backgroundGrey.hex,
        secondaryColor: colors.white.hex,
        downDiagonalOffset: 1,
      };
    case ThemeName.HOMEPAGE:
      return {
        primaryColor: colors.backgroundGrey.hex,
        secondaryColor: colors.white.hex,
        firstModuleColor: colors.backgroundBlue.hex,
        downDiagonalOffset: 1,
      };
    case ThemeName.BOTTOM_BLUE:
      return {
        primaryColor: colors.backgroundGrey.hex,
        secondaryColor: colors.white.hex,
        lastModuleColor: colors.backgroundBlue.hex,
        downDiagonalOffset: 1,
      };
    case ThemeName.DEFAULT_OFFSET_2:
      return {
        primaryColor: colors.white.hex,
        secondaryColor: colors.backgroundGrey.hex,
        downDiagonalOffset: 2,
      };
    case ThemeName.BOTTOM_BLUE_OFFSET_2:
      return {
        primaryColor: colors.white.hex,
        secondaryColor: colors.backgroundGrey.hex,
        lastModuleColor: colors.backgroundBlue.hex,
        downDiagonalOffset: 2,
      };
    case ThemeName.NO_VIN_HEADER:
      return {
        primaryColor: colors.backgroundGrey.hex,
        secondaryColor: colors.white.hex,
        firstModuleColor: colors.backgroundGrey.hex,
        downDiagonalOffset: 1,
      };
  }
};
