import React, { ReactNode } from "react";
import { Container } from "../../elements/Container";
import { styles } from "./VinSearchComponent.style";
import { useSettings } from "./utils/useSettings";
import SearchWrapper from "./SearchWrapper";

export interface VinSearchComponentProps {
  header: string;
  subtitle: string;
  children: ReactNode;
  bottomSubtitle: ReactNode;
  componentType: string;
  bottomSubtitleUrl: string;
  openModal: (event: any) => void;
}

export const VinSearchComponent: React.FC<VinSearchComponentProps> = ({
  header,
  subtitle,
  children,
  bottomSubtitle,
  componentType,
  bottomSubtitleUrl,
  openModal,
}) => {
  const { isMainHeading, isModalStyled, hasVinSearchWithFox, hasBlueBackground } = useSettings(componentType);
  return (
    <div css={styles.root(hasBlueBackground)}>
      <Container>
        {isMainHeading ? (
          <h1 css={styles.mainHeading(hasBlueBackground)}>{header}</h1>
        ) : (
          <h3 css={styles.secondHeading()}>{header}</h3>
        )}
        {!!subtitle && <p>{subtitle} </p>}
        <SearchWrapper withFox={hasVinSearchWithFox}>{children}</SearchWrapper>
        {!!bottomSubtitle && isModalStyled ? (
          <a
            css={styles.modal()}
            onClick={bottomSubtitleUrl ? null : openModal}
            href={bottomSubtitleUrl ? bottomSubtitleUrl : ""}
          >
            {bottomSubtitle}
          </a>
        ) : (
          <p>{bottomSubtitle}</p>
        )}
      </Container>
    </div>
  );
};
