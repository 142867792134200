import { css } from "@emotion/react";
import { colors, mediaQuery, spacing } from "../../core";

export const styles = {
  root: () =>
    css({
      position: "relative",
      width: "100vw",
      left: "50%",
      marginLeft: "-50vw",
      textAlign: "center",
      [mediaQuery.tabletOrLarger]: {
        marginTop: "4px",
      },
    }),
  partnerWrapper: () =>
    css({
      display: "flex",
      flexWrap: "wrap",
      justifyContent: "center",
      margin: `0 auto ${spacing.baseUnitMobile2} auto`,
      gap: spacing.baseUnitMobile1,
    }),
  partnerNote: () =>
    css({
      textTransform: "uppercase",
      color: colors.paleBlue.hex,
      fontWeight: 700,
    }),
  partnerLogo: () =>
    css({
      maxHeight: "18px",
    }),
  title: () =>
    css({
      maxWidth: "894px",
      margin: `0 auto ${spacing.baseUnitMobile2}`,
      color: colors.headlineGrey.hex,
      [mediaQuery.tabletOrLarger]: {
        margin: `0 auto ${spacing.baseUnitMobile3}`,
      },
    }),
  cardWrapper: () =>
    css({
      display: "flex",
      margin: "auto",
      button: {
        width: "100%",
        minWidth: "328px",
        margin: "auto",
      },
    }),
  carWrapper: () =>
    css({
      display: "block",
      textAlign: "left",
      width: "100%",
      margin: "auto",
    }),
  childrenContainer: () =>
    css({
      marginTop: spacing.baseUnitMobile2,
    }),
  limitedOfferTextWrapper: () =>
    css({
      margin: ` 0 auto ${spacing.baseUnitMobile2} `,
    }),
};
