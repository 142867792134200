import { css } from "@emotion/react";
import { colors } from "@carfax/web-ui/core";

const loadingColor = colors.paleBlue.hex;

export const styles = {
  loadingTxt: () =>
    css({
      color: loadingColor,
      fontWeight: "normal",
    }),
  loadingWrap: (stretchContainer: boolean) =>
    css({
      display: "flex",
      flexDirection: "column",
      justifyItems: "center",
      alignItems: "center",
      minHeight: stretchContainer ? "100vh" : "unset",
    }),
  marginTop: () =>
    css({
      marginTop: "20px",
    }),
};
