import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import { BLOCKS, Document, INLINES } from "@contentful/rich-text-types";
import { assetRenderer } from "components/Accessories/Contentful/AssetRenderer";
import { inlinesHandler } from "components/Accessories/Contentful/InlinesHandler";
import { headingAnchorRenderer } from "components/Accessories/Contentful/HeadingAnchorRenderer";
import { IModuleTextWithImageFields } from "contentful/types/generated/contentful";
import { tableRenderer } from "components/Accessories/Contentful/TableRenderer";
import { styles } from "./RichTextWrapper.style";

interface Props {
  content: Document;
  textAlign?: IModuleTextWithImageFields["textAlign"];
}

const RichTextWrapper: React.FC<Props> = ({ content, textAlign }) => {
  const options = {
    renderNode: {
      [BLOCKS.EMBEDDED_ASSET]: assetRenderer.renderImage,
      [INLINES.HYPERLINK]: inlinesHandler.renderHyperlink,
      [BLOCKS.HEADING_2]: headingAnchorRenderer.H2WithAnchor,
      [BLOCKS.HEADING_3]: headingAnchorRenderer.H3WithAnchor,
      [BLOCKS.TABLE]: tableRenderer.renderTable,
    },
  };
  return <div css={styles.content({ textAlign })}>{documentToReactComponents(content, options)}</div>;
};

export default RichTextWrapper;
