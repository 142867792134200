import { VinSearchCtxProvider } from "components/VinSearch/VinSearchContext";
import VinSearch from "../../../../components/VinSearch/VinSearch";

export interface IVinSearchComponentProps {
  errorMsgOnLoad?: string;
  vinOnLoad?: string;
}

const VinSearchComponent = ({ errorMsgOnLoad, vinOnLoad }: IVinSearchComponentProps) => (
  <VinSearchCtxProvider values={{ status: null, errorMsg: errorMsgOnLoad }}>
    <VinSearch onLoadProps={{ errorMsgOnLoad, vinOnLoad }} />
  </VinSearchCtxProvider>
);

export default VinSearchComponent;
