import IframeContainer from "./IframeContainer";

const renderVimeo = (uri) => {
  return (
    <IframeContainer>
      <iframe src={uri} frameBorder="0" allowFullScreen></iframe>
    </IframeContainer>
  );
};

export const vimeoRenderer = {
  renderVimeo,
};
