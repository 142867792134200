import React from "react";

import ClipLoader from "react-spinners/ClipLoader";
import BarLoader from "react-spinners/BarLoader";
import { colors } from "@carfax/web-ui/core";

interface Props {
  size?: number;
  color?: string;
  type?: LoaderTypes;
  loading?: boolean;
}

type LoaderTypes = "BarLoader" | "ClipLoader";

const Loading: React.FC<Props> = (props) => {
  const { size = 25, color = colors.black.hex, loading = true, type = "ClipLoader" } = props;
  if (type === "BarLoader") {
    return <BarLoader height={5} color={color} width={300} />;
  } else {
    return <ClipLoader size={size} color={color} loading={loading} />;
  }
};

export default Loading;
