import React from "react";
import { Modal } from "@carfax/web-ui/components/Modal";
import { useTranslation } from "app";
import WhereIsVinModalContent from "../WhereIsVinModalContent";

interface Props {
  modalOpen: boolean;
  setModalOpen: (isOpen: boolean) => void;
}

const ModalWhereIsVin: React.FC<Props> = ({ modalOpen, setModalOpen }) => {
  const { t } = useTranslation(["common"]);

  return (
    <Modal isOpen={modalOpen} onClose={() => setModalOpen(false)} closeText={t("modal.close")}>
      <WhereIsVinModalContent />
    </Modal>
  );
};

export default ModalWhereIsVin;
