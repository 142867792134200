import React from "react";
import { NoLicensePlateHeader } from "@carfax/web-ui/components/NoLicensePlateHeader";
import { useRouter } from "next/router";
import { linkUtil } from "../../../utils/i18n/link-util";
import { useTranslation } from "../../../app";
import { PartnerPackages } from "../../Preview/ComposePreview/Packages/utils/packages-naming";
import HowDoesItWorkModal from "./HowDoesItWorkModal/HowDoesItWorkModal";
import { useOneCreditContext } from "../../../utils/OneCreditPackageContext";
import { dataLayerECommerceEvents } from "domain/SeoAndAnalytics/GoogleAnalytics/dataLayerECommerceEvents";

export interface INoLicensePlateHeaderModuleWrapper {
  partnerNote?: string;
  partnerLogo?: {
    url: string;
    title?: string;
    width?: number;
    height?: number;
  };
  title: string;
  bottomSubtitle?: string;
}

const NoLicensePlateHeaderModule: React.FC<INoLicensePlateHeaderModuleWrapper> = ({
  partnerNote,
  partnerLogo,
  title,
  bottomSubtitle = "",
}) => {
  const oneCreditPackage = useOneCreditContext();
  const { t } = useTranslation(["common"]);
  const router = useRouter();
  const { pic, make, model } = router.query as {
    pic?: string;
    make?: string;
    model?: string;
  };

  const redirectToPayment = () => {
    dataLayerECommerceEvents.submitAddToCartEvent(
      PartnerPackages.ONE_CREDIT,
      oneCreditPackage.currency,
      oneCreditPackage.price
    );

    router.push(linkUtil.i18nLink(`/single-credit-packages?packageName=${PartnerPackages.ONE_CREDIT}`));
  };

  return (
    <NoLicensePlateHeader
      makeTitle={t("noLicensePlateHeader.makeTitle")}
      modelTitle={t("noLicensePlateHeader.modelTitle")}
      limitedOfferText={t("noLicensePlateHeader.limitedOfferText")}
      ctaText={t("noLicensePlateHeader.ctaText")}
      partnerNote={partnerNote}
      partnerLogo={partnerLogo}
      make={make}
      model={model}
      carImage={pic}
      title={title}
      ctaOnClick={() => redirectToPayment()}
    >
      {bottomSubtitle && <HowDoesItWorkModal modalLabel={bottomSubtitle} />}
    </NoLicensePlateHeader>
  );
};

export default NoLicensePlateHeaderModule;
