import React from "react";
import { styles } from "./ModulesWithTheme.style";
import { ThemeName, useTheme } from "./utils/useTheme";

export interface ModulesWithThemeProps {
  themeName: ThemeName;
  children: React.ReactNode[];
}

export const ModulesWithTheme: React.FC<ModulesWithThemeProps> = ({ themeName = ThemeName.DEFAULT, children }) => {
  const theme = useTheme(themeName);
  return <div css={styles.root(theme)}>{children}</div>;
};
