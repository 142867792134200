import { createContext, useContext } from "react";
import { Currency } from "@carfax/web-ui/core/PriceFormat";
import { PartnerPackages } from "../domain/Preview/ComposePreview/Packages/utils/packages-naming";
import { CreditsPackage } from "./pricing-response/types";

export const OneCreditPackageContext = createContext({
  name: PartnerPackages.ONE_CREDIT,
  creditsAmount: 0,
  price: 0,
  basePrice: 0,
  currency: Currency.EUR,
} as CreditsPackage);
export const useOneCreditContext = () => useContext(OneCreditPackageContext);
