import { dashify } from "utils/string/dashify";

const H2WithAnchor = (node) => {
  if (node.content.length === 0) {
    return null;
  }
  const textContent = node.content[0].value;
  return <h2 id={dashify(textContent)}>{textContent}</h2>;
};

const H3WithAnchor = (node) => {
  if (node.content.length === 0) {
    return null;
  }
  const textContent = node.content[0].value;
  return <h3 id={dashify(textContent)}>{textContent}</h3>;
};

export const headingAnchorRenderer = {
  H2WithAnchor,
  H3WithAnchor,
};
