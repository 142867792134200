import { ThemeStyles } from "./utils/useTheme";
import { css } from "@emotion/react";
import { mediaQuery } from "../../core/styles";

export const MODULE_PADDING_MOBILE = "60px";
export const MODULE_PADDING_DESKTOP = "100px";
export const VALUE_TO_FIX_BORDER_BUG = "1.01";

export const styles = {
  root: (theme: ThemeStyles) => {
    const { primaryColor, secondaryColor, firstModuleColor, lastModuleColor, downDiagonalOffset } = theme;
    return css({
      "& .module": {
        "& .moduleWrapper": {
          background: primaryColor,
          padding: `${MODULE_PADDING_MOBILE} 0`,
          [mediaQuery.desktopOrLarger]: {
            padding: `${MODULE_PADDING_DESKTOP} 0`,
          },
        },
        "&:nth-of-type(even)": {
          "& .moduleWrapper": {
            background: secondaryColor,
          },
          "& .splitterBottomColorBackground": {
            background: primaryColor,
          },
          "& .splitterTopColorPolygon": {
            background: secondaryColor,
          },
        },
        [`&:nth-of-type(3n + ${downDiagonalOffset})`]: {
          "& .splitterTopColorPolygon": {
            clipPath: "polygon(0 0, 100% 0, 100% 100%)",
            transform: `scaleY(${VALUE_TO_FIX_BORDER_BUG})`,
          },
        },
        "&:first-of-type": {
          "& .moduleWrapper": {
            background: firstModuleColor ? firstModuleColor : primaryColor,
          },
          "& .splitterTopColorPolygon": {
            background: firstModuleColor ? firstModuleColor : primaryColor,
          },
        },
        "&:nth-last-of-type(2)": {
          "& .splitterBottomColorBackground": {
            background: lastModuleColor,
          },
        },
        "&:last-of-type": {
          "& .moduleWrapper": {
            background: lastModuleColor,
          },
          "& .splitterBottomColorBackground": {
            display: "none",
          },
        },
      },
    });
  },
};
