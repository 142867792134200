const additionalContentReducer = (additionalContent, action) => {
  switch (action.type) {
    case "showContent":
      return { ...additionalContent, showContent: true };
    case "vinValidation":
      return { ...additionalContent, isVinValid: action.isVinValid };
    case "setTotalRecords":
      return { ...additionalContent, totalRecords: action.totalRecords };
    case "updateMake":
      return { ...additionalContent, make: action.make };
    case "updateEncryptedVin":
      return { ...additionalContent, encryptedVin: action.encryptedVin };
    case "updateVhrAlreadyBought":
      return { ...additionalContent, vhrAlreadyBought: true };
    default:
      return additionalContent;
  }
};

export { additionalContentReducer };
