import React from "react";
import { Container } from "../../elements/Container";
import { Button, ButtonVariants } from "../../elements/Button";
import { Image } from "../../elements/Image";
import { styles } from "./B2BHeader.style";
import { Grid } from "../../elements/Grid";
import { useThemePattern } from "./utils/useThemePattern";
import { calcAspectForImage } from "../../elements/Image/Image";

export interface B2BHeaderProps {
  partnerNote?: string;
  partnerLogo?: {
    url: string;
    title?: string;
    width?: number;
    height?: number;
  };
  title: string;
  description?: React.ReactNode;
  ctaText: string;
  ctaUrl: string;
  additionalLinkText?: string;
  additionalLinkElementId?: string;
  vinSearch: React.ReactNode;
  modalText: React.ReactNode;
  themePattern?: string;
  trustIconsText?: string;
  trustIcons?: { url: string; title?: string }[];
  withBigImage?: boolean;
  foxImage: {
    url: string;
    title?: string;
    width?: number;
    height?: number;
  };
  ctaOnClick: React.MouseEventHandler;
}

export const B2BHeader: React.FC<B2BHeaderProps> = ({
  partnerNote,
  partnerLogo,
  title,
  description,
  ctaText,
  ctaUrl,
  additionalLinkText,
  foxImage,
  additionalLinkElementId,
  ctaOnClick,
  vinSearch,
  modalText,
  trustIconsText,
  trustIcons,
  themePattern,
}) => {
  const themeConfig = useThemePattern(themePattern);
  const handleAdditionalLinkOnClick = () => {
    if (document?.getElementById(additionalLinkElementId)) {
      window.parent.scrollTo({
        behavior: "smooth",
        top: document.getElementById(additionalLinkElementId).getBoundingClientRect().top - 50,
      });
    }
  };

  const renderButton = () => {
    return (
      <Button
        css={styles.cta()}
        href={ctaUrl}
        onClick={ctaOnClick}
        variant={vinSearch ? ButtonVariants.Text : ButtonVariants.Standard}
      >
        {ctaText}
      </Button>
    );
  };

  return (
    <>
      <div css={styles.root(themeConfig)}>
        <Container paddingTop={["baseUnitMobile8", "baseUnitMobile8"]}>
          <Grid container>
            <Grid width={6}>
              <div css={styles.contentWrapper(themeConfig)}>
                {partnerNote && (
                  <div css={styles.partnerWrapper()}>
                    <span css={styles.partnerNote(themeConfig)}>{partnerNote}</span>
                    {partnerLogo && (
                      <img src={partnerLogo.url} alt={partnerLogo.title || ""} css={styles.partnerLogo()} />
                    )}
                  </div>
                )}
                <h1 css={styles.title(themeConfig)}>{title}</h1>
                {description && <div css={styles.textWrapper(themeConfig)}>{description}</div>}
                <div css={styles.vinSearchWrapper}>{vinSearch}</div>
                {modalText && <a css={styles.modalStyle(themeConfig)}>{modalText}</a>}
                {ctaText && renderButton()}
                {additionalLinkText && (
                  <div css={styles.additionalLinkWrapper()}>
                    <a css={styles.additionalLink(themeConfig)} onClick={handleAdditionalLinkOnClick}>
                      {additionalLinkText}
                    </a>
                  </div>
                )}
              </div>
            </Grid>
            <Grid width={6}>
              <div css={styles.foxImageWrapper(calcAspectForImage(foxImage), themeConfig)}>
                <Image image={foxImage} loadEagerly />
              </div>
            </Grid>
          </Grid>
        </Container>
      </div>
      {trustIcons && trustIconsText && (
        <div css={styles.trustIcons()}>
          <div>{trustIconsText}</div>
          {trustIcons.map((icon) => (
            <img key={icon.title} src={icon.url} alt={icon.title || ""} />
          ))}
        </div>
      )}
    </>
  );
};
