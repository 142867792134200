const renderTable = (node) => {
  return (
    <table>
      <tbody>
        {node.content.map((table, index) => {
          const tableContent = table.content;
          const tableRow = tableContent.map((row) => row.content[0].content);
          return (
            <tr key={index}>
              {tableRow.map((tableCell, cellIndex) => (
                <td key={cellIndex}>{tableCell[0].value}</td>
              ))}
            </tr>
          );
        })}
      </tbody>
    </table>
  );
};

export const tableRenderer = {
  renderTable,
};
