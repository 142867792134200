export const enum VinSearchType {
  HOMEPAGE_HEADER = "homepage header",
  PARTNER_HEADER_WITH_LOGO = "partner header",
  BLUE_VIN_SEARCH = "blue vin search",
  GENERIC_HEADER = "generic header",
  NO_LICENSE_PLATE_HEADER = "no vin header",
}

export interface ComponentSettings {
  isMainHeading: boolean;
  isModalStyled?: boolean;
  hasVinSearchWithFox: boolean;
  hasBlueBackground: boolean;
}

export const useSettings = (componentType: string): ComponentSettings => {
  switch (componentType) {
    case VinSearchType.HOMEPAGE_HEADER:
      return {
        hasVinSearchWithFox: true,
        hasBlueBackground: true,
        isMainHeading: true,
        isModalStyled: true,
      };
    case VinSearchType.BLUE_VIN_SEARCH:
      return {
        hasVinSearchWithFox: false,
        hasBlueBackground: true,
        isMainHeading: false,
        isModalStyled: true,
      };
    case VinSearchType.GENERIC_HEADER:
      return {
        hasVinSearchWithFox: false,
        hasBlueBackground: false,
        isMainHeading: true,
        isModalStyled: false,
      };
    default:
      return {
        hasVinSearchWithFox: false,
        hasBlueBackground: false,
        isMainHeading: false,
        isModalStyled: false,
      };
  }
};
