import React, { ReactNode, useState } from "react";
import { styles } from "../VinSearchComponent.style";
import FoxImages from "../utils/FoxImages";

export interface SearchWrapperProps {
  withFox: boolean;
  children: ReactNode;
}

export const SearchWrapper: React.FC<SearchWrapperProps> = ({ withFox, children }) => {
  const [focused, setFocused] = useState(false);

  return (
    <div css={styles.wrapper(withFox)} onFocus={() => setFocused(true)} onBlur={() => setFocused(false)}>
      {children}
      {withFox && <FoxImages focused={focused} />}
    </div>
  );
};

export default SearchWrapper;
