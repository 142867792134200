import React, { useEffect, useState } from "react";
import { ModuleSampleVhr } from "../../../contentful/PageModularModule";
import { SampleVhrComponent } from "@carfax/web-ui/components/SampleVhr";
import { useStores } from "../../../hooks";
import { observer, useTranslation } from "app";
import { Button, ButtonVariants } from "@carfax/web-ui/elements/Button";
import SampleReport from "components/SampleReport";
import RichTextWrapper from "../TextImage/components/RichTextWrapper";

interface Props {
  module: ModuleSampleVhr;
  splitterEnabled: boolean;
}
const SampleVhr: React.FC<Props> = observer(({ module, splitterEnabled }) => {
  const { browserStore } = useStores();
  const { t } = useTranslation(["common"]);
  const [isDesktop, setIsDesktop] = useState(false);

  const primaryImage = isDesktop ? module.primaryDesktopImage : module.primaryMobileImage;
  const secondaryImage = isDesktop ? module.secondaryDesktopImage : module.secondaryMobileImage;

  const imageUrl = splitterEnabled ? secondaryImage : primaryImage;

  useEffect(() => {
    setIsDesktop(browserStore.isDesktop);
  }, [browserStore.isDesktop]);

  return (
    <SampleVhrComponent
      imageSource={imageUrl}
      alt={module.primaryDesktopImage?.title}
      title={module.title}
      text={<RichTextWrapper content={module.text} textAlign="center" />}
      isDesktop={isDesktop}
    >
      <SampleReport
        optionalDesktopReport={module.optionalDesktopReport}
        optionalMobileReport={module.optionalMobileReport}
      >
        <Button variant={ButtonVariants.Outlined}>{t("vinWidget.sampleReportLink.text")}</Button>
      </SampleReport>
    </SampleVhrComponent>
  );
});

export default SampleVhr;
