import { dataLayerEvents } from "domain/SeoAndAnalytics/GoogleAnalytics/dataLayerEvents";

const successEvent = (isVinSearch: boolean) => {
  dataLayerEvents.submitBolloCalculationEvent({ event: "calculation_success", isVinSearch: isVinSearch });
};

const errorEvent = (errorMessage: string, isVinSearch: boolean) => {
  dataLayerEvents.submitBolloCalculationEvent({
    event: "calculation_error",
    errorMessage: errorMessage,
    isVinSearch: isVinSearch,
  });
};

const switchTabEvent = (eventWithVin: boolean) => {
  dataLayerEvents.submitSwitchBolloCalculatorTab(eventWithVin);
};

export const dataLayer = {
  successEvent,
  errorEvent,
  switchTabEvent,
};
