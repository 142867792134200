import React, { useMemo } from "react";
import { styles } from "../VinSearchComponent.style";

interface FoxImagesProps {
  focused: boolean;
}

export const FoxImages: React.FC<FoxImagesProps> = ({ focused }) => {
  const LOOK_UP_TITLE = "Carfox looking ahead";
  const LOOK_DOWN_TITLE = "Carfox looking down";

  const lookUpSources = (
    <>
      <source srcSet="/static/images/vin-fox-looking-ahead-248x185-2x.webp" type="image/webp" />
      <source srcSet="/static/images/vin-fox-looking-ahead-248x185-2x.png" type="image/png" />
    </>
  );

  const lookDownSources = (
    <>
      <source srcSet="/static/images/vin-fox-looking-down-248x185-2x.webp" type="image/webp" />
      <source srcSet="/static/images/vin-fox-looking-down-248x185-2x.png" type="image/png" />
    </>
  );
  const sources = useMemo(() => (focused ? lookUpSources : lookDownSources), [focused]);
  const title = useMemo(() => (focused ? LOOK_UP_TITLE : LOOK_DOWN_TITLE), [focused]);

  return (
    <picture css={styles.searchFoxImg}>
      {sources}
      <img
        alt={title}
        src="/static/images/vin-fox-looking-ahead-248x185-2x.png"
        title={title}
        width="124px"
        height="92px"
      />
    </picture>
  );
};

export default FoxImages;
