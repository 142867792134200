import { css } from "@emotion/react";
import { spacing } from "@carfax/web-ui/core";

export const styles = {
  modalTrigger: () =>
    css({
      fontWeight: 700,
      margin: `${spacing.baseUnitMobile2} auto`,
    }),
};
