import { css, keyframes } from "@emotion/react";
import { Icons } from "../../../../elements/Icon";
import { spacing } from "../../../../core";

const rotate = keyframes`
  0% { transform: rotate(0deg); opacity: 1; webkitTransform: rotate(0deg);}
  50% { opacity: 0.6; transform: rotate(210deg); webkitTransform: rotate(210deg); }
  80% { transform: rotate(80deg); webkitTransform: rotate(80deg); }
  100% { transform: rotate(360deg); opacity: 1; webkitTransform: rotate(360deg); }
`;

const grow = keyframes`
0% { transform: scale(1); webkitTransform: scale(1); }
40% { transform: scale(0.8); webkitTransform: scale(0.8); }
80% { transform: scale(1.2); webkitTransform: scale(1.2); }
100% { transform: scale(1); webkitTransform: scale(1); }
`;

const iconStyles = {
  width: "20px",
  height: "20px",
  backgroundSize: "cover",
  marginRight: spacing.baseUnitMobile2,
};

export const styles = {
  successIcon: () =>
    css({
      ...iconStyles,
      backgroundImage: `url(${Icons.checkMarkGreen})`,
      animation: `${grow} .4s linear forwards`,
    }),

  loadingIcon: () =>
    css({
      ...iconStyles,
      backgroundImage: `url(${Icons.loadingIcon})`,
      animation: `${rotate} 1s linear infinite`,
    }),
};
