import React, { ReactNode } from "react";
import { Container } from "../../elements/Container";
import { Image } from "../../elements/Image";
import { styles } from "./SampleVhrComponent.style";

export interface SampleVhrComponentProps {
  imageSource: {
    url: string;
    width?: number;
    height?: number;
    title?: string;
  };
  alt: string;
  title: string;
  text?: ReactNode;
  children: ReactNode;
  isDesktop: boolean;
}

export const SampleVhrComponent: React.FC<SampleVhrComponentProps> = ({
  imageSource,
  title,
  text = "",
  children,
  isDesktop,
}) => {
  const renderTitle = () => {
    return (
      <div css={styles.titleWrapper()}>
        <h2>{title}</h2>
      </div>
    );
  };

  const renderImage = () => {
    return (
      <div css={styles.imageWrapper()}>
        <Image image={imageSource} />
      </div>
    );
  };

  const renderDesktopSampleVhr = () => {
    return (
      <Container>
        <div css={styles.contentWrapper()}>
          {renderImage()}
          <div css={styles.textWrapper()}>
            {renderTitle()}
            <div css={styles.subtitleWrapper()}>{text}</div>
            {children}
          </div>
        </div>
      </Container>
    );
  };

  const renderMobileSampleVhr = () => {
    return (
      <Container>
        <div css={styles.contentWrapper()}>
          <div css={styles.textWrapper()}>
            {renderTitle()}
            {renderImage()}
            <div css={styles.subtitleWrapper()}>{text}</div>
            {children}
          </div>
        </div>
      </Container>
    );
  };

  return isDesktop ? renderDesktopSampleVhr() : renderMobileSampleVhr();
};
