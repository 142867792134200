import React from "react";

export interface ModularPageComponentProps {
  disableSplitter?: boolean;
  children: React.ReactNode;
}

export const ModularPageComponent: React.FC<ModularPageComponentProps> = ({ children }) => {
  return (
    <div className="module">
      <div className="moduleWrapper">{children}</div>
    </div>
  );
};
