import React from "react";
import { Container } from "../../elements/Container";
import { Grid } from "../../elements/Grid";
import { styles } from "./NoLicensePlateHeader.style";
import { Button, ButtonVariants } from "../../elements/Button";
import { CarDetails } from "../CarSubmission/components/CarDetails";

export interface NoLicensePlateHeaderProps {
  partnerNote?: string;
  partnerLogo?: {
    url: string;
    title?: string;
    width?: number;
    height?: number;
  };
  title: string;
  make?: string;
  carImage?: string;
  makeTitle: string;
  modelTitle: string;
  model?: string;
  ctaOnClick: React.MouseEventHandler;
  ctaText: string;
  limitedOfferText: string;
}

export const NoLicensePlateHeader: React.FC<React.PropsWithChildren<NoLicensePlateHeaderProps>> = ({
  partnerNote = "",
  partnerLogo,
  title,
  make,
  carImage,
  makeTitle,
  ctaOnClick,
  ctaText,
  limitedOfferText,
  modelTitle,
  model,
  children,
}) => {
  const getCarDetails = () => {
    const carDetails = [];
    if (make) {
      carDetails.push({
        name: makeTitle,
        value: make,
      });
    }
    if (model) {
      carDetails.push({
        name: modelTitle,
        value: model,
      });
    }
    return carDetails;
  };

  return (
    <div css={styles.root()}>
      <Container>
        <div>
          <Grid container>
            {partnerLogo && (
              <div css={styles.partnerWrapper()}>
                <span css={styles.partnerNote()}>{partnerNote}</span>
                <img src={partnerLogo.url} alt={partnerLogo.title || ""} css={styles.partnerLogo()} />
              </div>
            )}
          </Grid>
          <Grid container>
            <h1 css={styles.title()}>{title}</h1>
          </Grid>
          <Grid container>
            <div css={styles.carWrapper()}>
              <CarDetails details={getCarDetails()} carImage={carImage} />
            </div>
          </Grid>
          <Grid container>
            <span css={styles.limitedOfferTextWrapper()}>{limitedOfferText}</span>
          </Grid>
          <Grid container>
            <div css={styles.cardWrapper()}>
              <Button variant={ButtonVariants.Standard} onClick={ctaOnClick}>
                {ctaText}
              </Button>
            </div>
          </Grid>
          {children && <div css={styles.childrenContainer}>{children}</div>}
        </div>
      </Container>
    </div>
  );
};
