import { css } from "@emotion/react";
import { colors, fontSizes, mediaQuery, spacing } from "@carfax/web-ui/core";

export const styles = {
  sampleReportDesktop: () =>
    css({
      [mediaQuery.tabletOrLarger]: {
        marginTop: spacing.baseUnitMobile2,
        width: "100%",
      },
      [mediaQuery.desktopOrLarger]: {
        maxWidth: "800px",
        marginTop: spacing.baseUnitMobile5,
      },
    }),
  sampleReportMobile: () =>
    css({
      width: "100%",
    }),
  sampleReportMobileSection: () =>
    css({
      [mediaQuery.tabletOrLarger]: {
        display: "none",
      },
    }),
  sampleReportWrapMobile: (sampleReportMobileExtended: boolean) => {
    const bottomPadding = 50;
    return css({
      textAlign: "center",
      backgroundColor: colors.backgroundGrey.hex,
      height: sampleReportMobileExtended ? `100%` : `500px`,
      borderBottom: `1px solid ${colors.lightGrey.hex}`,
      boxShadow: `inset 0 -13px 6px 0 #ffffffed`,
      paddingLeft: `${spacing.baseUnitMobile2}`,
      paddingRight: `${spacing.baseUnitMobile2}`,
      paddingBottom: sampleReportMobileExtended ? `${bottomPadding}px` : 0,
      position: "relative",
      left: "50%",
      width: "100vw",
      marginLeft: "-50vw",
      marginTop: spacing.baseUnitMobile3,
      overflow: "hidden",
    });
  },
  seeMoreIcWrap: () => {
    const size = 30;
    return css({
      width: `${size * 2}px`,
      height: `${size}px`,
      backgroundColor: colors.white.hex,
      borderBottomLeftRadius: `${size}px`,
      borderBottomRightRadius: `${size}px`,
      border: `1px solid ${colors.lightGrey.hex}`,
      borderTop: 0,
      margin: `-1px auto 0`,
      paddingTop: `10px`,
      position: "relative",
      display: "flex",
      justifyContent: "center",
    });
  },
  openNewTabWrapper: () =>
    css({
      padding: `${spacing.baseUnitMobile1} 0`,
      textAlign: "right",
    }),
  seeMoreIc: (isVhrOpen: boolean) =>
    css({
      display: "flex",
      height: `47px`,
      width: `47px`,
      border: `1px solid ${colors.paleBlue.hex}`,
      borderRadius: "50%",
      justifyContent: "center",
      alignItems: "center",
      background: colors.white.hex,
      margin: "-34px auto 0",
      transform: `rotate(${isVhrOpen ? 180 : 0}deg)`,
      svg: {
        height: `17px`,
        width: `17px`,
        marginBottom: `-1px`,
      },
      path: {
        fill: colors.paleBlue.hex,
      },
    }),
  seeMoreTxt: () =>
    css({
      textAlign: "center",
      marginTop: `-25px`,
      marginLeft: `-130px`,
      color: colors.paleBlue.hex,
      fontWeight: "bold",
      fontSize: fontSizes.body.cat,
      [mediaQuery.tabletOrLarger]: {
        marginTop: `-${spacing.baseUnitMobile2}`,
        marginLeft: `-150px`,
        fontSize: fontSizes.body.horse,
      },
    }),
  buttonWrapper: () =>
    css({
      width: "fit-content",
      margin: `${spacing.baseUnitMobile3} auto 0`,
    }),
};
