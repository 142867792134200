import { colors, fontSizes, letterSpacings, lineHeights, mediaQuery, spacing } from "@carfax/web-ui";
import { css } from "@emotion/react";

export const styles = {
  content: ({ textAlign }) =>
    css({
      fontSize: fontSizes.body.horse,
      lineHeight: lineHeights.body.horse,
      letterSpacing: letterSpacings.body.horse,
      whiteSpace: "pre-wrap",
      textAlign: textAlign || "left",
      marginTop: 0,
      "h2, h1": {
        fontSize: fontSizes.heading.H2.mobile,
        lineHeight: lineHeights.heading.H2.mobile,
        letterSpacing: letterSpacings.heading.H2.mobile,
        marginTop: 0,
        whiteSpace: "normal",
        [mediaQuery.desktopOrLarger]: {
          fontSize: fontSizes.heading.H2.desktop,
          lineHeight: lineHeights.heading.H2.desktop,
          letterSpacing: letterSpacings.heading.H2.desktop,
          marginTop: 0,
        },
      },

      ul: {
        marginBottom: spacing.baseUnitMobile3,
        textAlign: "left",
        marginLeft: "18px",
        li: {
          listStyle: "disc",
          margin: `0 0 0 16px`,
          paddingLeft: "4px",
          fontSize: fontSizes.body.cat,
          lineHeight: lineHeights.body.cat,
          letterSpacing: letterSpacings.body.cat,
          [mediaQuery.desktopOrLarger]: {
            fontSize: fontSizes.body.horse,
            lineHeight: lineHeights.body.horse,
            letterSpacing: letterSpacings.body.horse,
          },
          "&:last-of-type": {
            paddingBottom: 0,
          },
          p: {
            marginBottom: 0,
          },
          "&::marker": {
            fontSize: fontSizes.heading.H3.mobile,
            [mediaQuery.desktopOrLarger]: {
              fontSize: fontSizes.body.horse,
            },
          },
        },
      },
      ol: {
        marginBottom: spacing.baseUnitMobile3,
        textAlign: "left",

        li: {
          listStyle: "decimal",
          margin: `0 0 0 20px`,
          paddingLeft: `28px`,
          fontSize: fontSizes.body.cat,
          lineHeight: lineHeights.body.cat,
          letterSpacing: letterSpacings.body.cat,
          [mediaQuery.desktopOrLarger]: {
            fontSize: fontSizes.body.horse,
            lineHeight: lineHeights.body.horse,
            letterSpacing: letterSpacings.body.horse,
          },
          p: {
            marginBottom: 0,
          },
        },
      },
      p: {
        fontSize: fontSizes.body.horse,
        lineHeight: lineHeights.body.horse,
        letterSpacing: letterSpacings.body.horse,
      },
      "p:last-of-type": {
        margin: 0,
      },
      "p,ul:nth-last-of-type(-n+2)": {
        marginBottom: 0,
      },
      b: {
        fontWeight: "bold",
      },
      "p ~ ul": {
        paddingTop: spacing.baseUnitMobile3,
      },
      table: {
        border: `solid 1px ${colors.lightGrey.hex}`,
        borderRadius: "8px",
        display: "flex",
        flexDirection: "column",
        tr: {
          display: "flex",
          borderBottom: `solid 1px ${colors.lightGrey.hex}`,
          "&:first-of-type": {
            backgroundColor: `rgba(${colors.lightGrey.rgba}, 0.1)`,
            minHeight: "68px",
            fontWeight: 700,
            fontSize: fontSizes.body.horse,
            letterSpacing: letterSpacings.body.horse,
          },
          "&:last-of-type": {
            borderBottom: "none",
          },
        },
        td: {
          display: "flex",
          alignItems: "center",
          maxWidth: "176px",
          minWidth: "95px",
          width: "100%",
          padding: `10px 12px`,
          borderRight: `solid 1px ${colors.lightGrey.hex}`,
          "&:last-of-type": {
            borderRight: "none",
          },
        },
      },
    }),
};
