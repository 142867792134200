import { colors, spacing } from "../../../core";

enum ThemeName {
  HALLOWEEN = "halloween",
  BLACK_FRIDAY = "black friday",
  LIGHT_GREY = "light grey",
}

export interface ThemeStyles {
  background?: string;
  titleColor?: string;
  descriptionColor?: string;
  linkColor?: string;
  topMargin?: string;
  bannerBackground?: string;
  counterColor?: string;
  isGreyText?: boolean;
  noMarginBottom?: boolean;
  isBigImage?: boolean;
}

export const useThemePattern = (theme): ThemeStyles => {
  switch (theme) {
    case ThemeName.HALLOWEEN:
      return {
        background: colors.halloweenPurple.hex,
        titleColor: colors.white.hex,
        descriptionColor: colors.white.hex,
        linkColor: colors.white.hex,
        topMargin: spacing.baseUnitMobile4,
        counterColor: colors.strongOrange.hex,
      };
    case ThemeName.BLACK_FRIDAY:
      return {
        background: colors.black.hex,
        titleColor: colors.white.hex,
        descriptionColor: colors.white.hex,
        linkColor: colors.white.hex,
        topMargin: spacing.baseUnitMobile4,
      };
    case ThemeName.LIGHT_GREY:
      return {
        background: colors.backgroundGrey.hex,
        isGreyText: true,
        noMarginBottom: true,
        isBigImage: true,
      };
    default:
      return {
        background: colors.backgroundGrey.hex,
      };
  }
};
