import React from "react";
import { Container } from "../../elements/Container";
import { Button } from "../../elements/Button";
import { Image } from "../../elements/Image";
import { Grid } from "../../elements/Grid";
import { styles } from "./VoucherEntryHeader.style";
import { VoucherEntryFieldLandingPage, VoucherInputProps } from "./VoucnerEntryField/VoucherEntryFieldLandingPage";

export interface IVoucherEntryHeader {
  headline: string;
  description: string;
  ctaText: string;
  onVoucherEntry: () => void;
  image: {
    url: string;
    title?: string;
    width?: number;
    height?: number;
  };
  inputProps: VoucherInputProps;
}

export const VoucherEntryHeader: React.FC<IVoucherEntryHeader> = ({
  headline,
  description,
  ctaText,
  onVoucherEntry,
  image,
  inputProps,
}) => {
  return (
    <div css={styles.root()}>
      <Container paddingTop={"baseUnitMobile8"}>
        <Grid container>
          <Grid width={6}>
            <div css={styles.contentWrapper()}>
              <h1 css={styles.title()}>{headline}</h1>
              <p css={styles.textWrapper()}>{description}</p>
              <VoucherEntryFieldLandingPage {...inputProps} />
              {ctaText && (
                <Button css={styles.cta()} onClick={onVoucherEntry}>
                  {ctaText}
                </Button>
              )}
            </div>
          </Grid>
          <Grid width={6}>
            <Image image={image} loadEagerly />
          </Grid>
        </Grid>
      </Container>
    </div>
  );
};
