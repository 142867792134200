const getValueFromArray = (array, value: string) => {
  const valueFromArray = array?.find(({ name }) => name === value);
  return valueFromArray;
};

const adjustEuroStandard = (euroStandard: string) => {
  return euroStandard.replace("EURO_", "Euro ");
};

export const bolloCalculatorUtil = { adjustEuroStandard, getValueFromArray };
