import { css, keyframes } from "@emotion/react";
import { colors, fontSizes, letterSpacings, lineHeights, spacing } from "../../../core/styles";
import { Icons } from "../../../elements/Icon";

const grow = keyframes`
  0% { transform: scale(1); webkitTransform: scale(1); }
  40% { transform: scale(0.8); webkitTransform: scale(0.8); }
  80% { transform: scale(1.2); webkitTransform: scale(1.2); }
  100% { transform: scale(1); webkitTransform: scale(1); }
`;

const rotate = keyframes`
  0% { transform: rotate(0deg); opacity: 1; webkitTransform: rotate(0deg);}
  50% { opacity: 0.6; transform: rotate(210deg); webkitTransform: rotate(210deg); }
  80% { transform: rotate(80deg); webkitTransform: rotate(80deg); }
  100% { transform: rotate(360deg); opacity: 1; webkitTransform: rotate(360deg); }
`;

export const styles = {
  form: () =>
    css({
      position: "relative",
      maxWidth: "436px",
    }),

  loadingIcon: () =>
    css({
      backgroundImage: `url(${Icons.loadingIcon})`,
      width: spacing.baseUnitMobile2,
      height: spacing.baseUnitMobile2,
      backgroundSize: "cover",
      marginRight: spacing.baseUnitMobile1,
      animation: `${rotate} 1s linear infinite`,
    }),
  searchIconWrapper: () =>
    css({
      backgroundColor: "transparent",
      border: "none",
      outline: "none",
      right: "0",
      path: {
        fill: colors.linkBlue.hex,
      },
    }),
  input: (hasError) =>
    css({
      textTransform: "uppercase",
      borderColor: hasError ? colors.red.hex : colors.lightGrey.hex,
      borderRadius: "4px",
      backgroundColor: colors.white.hex,
      padding: `${spacing.baseUnitMobile2} ${spacing.baseUnitMobile2}`,
      fontSize: fontSizes.body.horse,
      lineHeight: lineHeights.body.horse,
      letterSpacing: letterSpacings.body.horse,
      color: colors.headlineGrey.hex,
      width: "100%",
      height: "100%",
      margin: "0 auto",
      transition: "all .3s ease",

      "::placeholder": {
        color: colors.headlineGrey.hex,
        textTransform: "none",
        fontSize: fontSizes.body.mouse,
        lineHeight: lineHeights.body.mouse,
        letterSpacing: letterSpacings.body.mouse,
      },
      "&:focus": {
        boxShadow: "0 0 10px 0 rgba(0,0,0,0.3)",
        borderColor: colors.linkBlue.hex,
        outline: 0,
      },
    }),
  error: () =>
    css({
      padding: `${spacing.baseUnitMobile1} ${spacing.baseUnitMobile2}`,
      color: colors.red.hex,
      fontSize: fontSizes.body.mouse,
      lineHeight: lineHeights.body.mouse,
      letterSpacing: letterSpacings.body.mouse,
    }),
  checkIcon: () =>
    css({
      backgroundImage: `url(${Icons.checkMark})`,
      width: spacing.baseUnitMobile4,
      height: spacing.baseUnitMobile4,
      backgroundSize: "cover",
      animation: `${grow} .4s linear forwards`,
    }),
};
