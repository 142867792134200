const linkIsExternal = (uri) => {
  return uri.includes("https://") || uri.includes("http://") || uri.includes("mailto:") || uri.includes("tel:");
};

const linkIsFromYoutube = (uri) => {
  return uri.includes("youtube.com/embed");
};

const linkIsFromVimeo = (uri) => {
  return uri.includes("player.vimeo.com/video");
};

export const linkOriginResolver = {
  linkIsExternal,
  linkIsFromYoutube,
  linkIsFromVimeo,
};
