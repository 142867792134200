import { css } from "@emotion/react";
import { spacing, mediaQuery, fontSizes, lineHeights } from "../../../../core/styles";

export const styles = {
  vinMessage: () =>
    css({
      margin: "0 auto",
      lineHeight: lineHeights.body.horse,
      fontSize: fontSizes.body.horse,
      letterSpacing: "0.5px",
      textAlign: "center",
    }),

  vhrFound: () =>
    css({
      margin: "0 auto",
      lineHeight: lineHeights.body.horse,
      fontSize: fontSizes.body.horse,
      textAlign: "center",
      fontWeight: 700,
    }),
  ctaWrapper: () =>
    css({
      display: "flex",
      justifyContent: "center",
      margin: `${spacing.baseUnitMobile2} 0 0`,
      [mediaQuery.desktopOrLarger]: {
        margin: `${spacing.baseUnitMobile3} 0 0`,
      },
    }),
};
