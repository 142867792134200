export const taxConfig = [
  {
    name: "General",
    below100KW: [
      { name: "Euro 0", value: 3.0 },
      { name: "Euro 1", value: 2.9 },
      { name: "Euro 2", value: 2.8 },
      { name: "Euro 3", value: 2.7 },
      { name: "Euro 4", value: 2.58 },
      { name: "Euro 5", value: 2.58 },
      { name: "Euro 6", value: 2.58 },
    ],
    above100KW: [
      { name: "Euro 0", value: 4.5 },
      { name: "Euro 1", value: 4.35 },
      { name: "Euro 2", value: 4.2 },
      { name: "Euro 3", value: 4.05 },
      { name: "Euro 4", value: 3.87 },
      { name: "Euro 5", value: 3.87 },
      { name: "Euro 6", value: 3.87 },
    ],
  },
  {
    name: "Abruzzo",
    below100KW: [
      { name: "Euro 0", value: 3.63 },
      { name: "Euro 1", value: 3.51 },
      { name: "Euro 2", value: 3.39 },
      { name: "Euro 3", value: 3.27 },
      { name: "Euro 4", value: 3.12 },
      { name: "Euro 5", value: 3.12 },
      { name: "Euro 6", value: 3.12 },
    ],
    above100KW: [
      { name: "Euro 0", value: 5.45 },
      { name: "Euro 1", value: 5.27 },
      { name: "Euro 2", value: 5.08 },
      { name: "Euro 3", value: 4.91 },
      { name: "Euro 4", value: 4.69 },
      { name: "Euro 5", value: 4.69 },
      { name: "Euro 6", value: 4.69 },
    ],
  },
  {
    name: "Basilicata",
    below100KW: [
      { name: "Euro 0", value: 3.0 },
      { name: "Euro 1", value: 2.9 },
      { name: "Euro 2", value: 2.8 },
      { name: "Euro 3", value: 2.7 },
      { name: "Euro 4", value: 2.58 },
      { name: "Euro 5", value: 2.58 },
      { name: "Euro 6", value: 2.58 },
    ],
    above100KW: [
      { name: "Euro 0", value: 4.5 },
      { name: "Euro 1", value: 4.35 },
      { name: "Euro 2", value: 4.2 },
      { name: "Euro 3", value: 4.05 },
      { name: "Euro 4", value: 3.87 },
      { name: "Euro 5", value: 3.87 },
      { name: "Euro 6", value: 3.87 },
    ],
  },

  {
    name: "Calabria",
    below100KW: [
      { name: "Euro 0", value: 3.3 },
      { name: "Euro 1", value: 3.19 },
      { name: "Euro 2", value: 3.08 },
      { name: "Euro 3", value: 2.97 },
      { name: "Euro 4", value: 2.84 },
      { name: "Euro 5", value: 2.84 },
      { name: "Euro 6", value: 2.84 },
    ],
    above100KW: [
      { name: "Euro 0", value: 4.95 },
      { name: "Euro 1", value: 4.79 },
      { name: "Euro 2", value: 4.62 },
      { name: "Euro 3", value: 4.46 },
      { name: "Euro 4", value: 4.26 },
      { name: "Euro 5", value: 4.26 },
      { name: "Euro 6", value: 4.26 },
    ],
  },
  {
    name: "Campania",
    below100KW: [
      { name: "Euro 0", value: 3.63 },
      { name: "Euro 1", value: 3.51 },
      { name: "Euro 2", value: 3.39 },
      { name: "Euro 3", value: 3.27 },
      { name: "Euro 4", value: 3.12 },
      { name: "Euro 5", value: 3.12 },
      { name: "Euro 6", value: 3.12 },
    ],
    above100KW: [
      { name: "Euro 0", value: 5.45 },
      { name: "Euro 1", value: 5.27 },
      { name: "Euro 2", value: 5.08 },
      { name: "Euro 3", value: 4.91 },
      { name: "Euro 4", value: 4.69 },
      { name: "Euro 5", value: 4.69 },
      { name: "Euro 6", value: 4.69 },
    ],
  },
  {
    name: "Emilia Romagna",
    below100KW: [
      { name: "Euro 0", value: 3.0 },
      { name: "Euro 1", value: 2.9 },
      { name: "Euro 2", value: 2.8 },
      { name: "Euro 3", value: 2.7 },
      { name: "Euro 4", value: 2.58 },
      { name: "Euro 5", value: 2.58 },
      { name: "Euro 6", value: 2.58 },
    ],
    above100KW: [
      { name: "Euro 0", value: 4.5 },
      { name: "Euro 1", value: 4.35 },
      { name: "Euro 2", value: 4.2 },
      { name: "Euro 3", value: 4.05 },
      { name: "Euro 4", value: 3.87 },
      { name: "Euro 5", value: 3.87 },
      { name: "Euro 6", value: 3.87 },
    ],
  },
  {
    name: "Friuli Venezia Giulia",
    below100KW: [
      { name: "Euro 0", value: 3.0 },
      { name: "Euro 1", value: 2.9 },
      { name: "Euro 2", value: 2.8 },
      { name: "Euro 3", value: 2.7 },
      { name: "Euro 4", value: 2.58 },
      { name: "Euro 5", value: 2.58 },
      { name: "Euro 6", value: 2.58 },
    ],
    above100KW: [
      { name: "Euro 0", value: 4.5 },
      { name: "Euro 1", value: 4.35 },
      { name: "Euro 2", value: 4.2 },
      { name: "Euro 3", value: 4.05 },
      { name: "Euro 4", value: 3.87 },
      { name: "Euro 5", value: 3.87 },
      { name: "Euro 6", value: 3.87 },
    ],
  },
  {
    name: "Lazio",
    below100KW: [
      { name: "Euro 0", value: 3.3 },
      { name: "Euro 1", value: 3.19 },
      { name: "Euro 2", value: 3.08 },
      { name: "Euro 3", value: 2.97 },
      { name: "Euro 4", value: 2.84 },
      { name: "Euro 5", value: 2.84 },
      { name: "Euro 6", value: 2.84 },
    ],
    above100KW: [
      { name: "Euro 0", value: 4.95 },
      { name: "Euro 1", value: 4.79 },
      { name: "Euro 2", value: 4.62 },
      { name: "Euro 3", value: 4.46 },
      { name: "Euro 4", value: 4.26 },
      { name: "Euro 5", value: 4.26 },
      { name: "Euro 6", value: 4.26 },
    ],
  },
  {
    name: "Liguria",
    below100KW: [
      { name: "Euro 0", value: 3.3 },
      { name: "Euro 1", value: 3.19 },
      { name: "Euro 2", value: 3.08 },
      { name: "Euro 3", value: 2.97 },
      { name: "Euro 4", value: 2.84 },
      { name: "Euro 5", value: 2.84 },
      { name: "Euro 6", value: 2.84 },
    ],
    above100KW: [
      { name: "Euro 0", value: 4.95 },
      { name: "Euro 1", value: 4.79 },
      { name: "Euro 2", value: 4.62 },
      { name: "Euro 3", value: 4.46 },
      { name: "Euro 4", value: 4.26 },
      { name: "Euro 5", value: 4.26 },
      { name: "Euro 6", value: 4.26 },
    ],
  },
  {
    name: "Lombardia",
    below100KW: [
      { name: "Euro 0", value: 3.0 },
      { name: "Euro 1", value: 2.9 },
      { name: "Euro 2", value: 2.8 },
      { name: "Euro 3", value: 2.7 },
      { name: "Euro 4", value: 2.58 },
      { name: "Euro 5", value: 2.58 },
      { name: "Euro 6", value: 2.58 },
    ],
    above100KW: [
      { name: "Euro 0", value: 4.5 },
      { name: "Euro 1", value: 4.35 },
      { name: "Euro 2", value: 4.2 },
      { name: "Euro 3", value: 4.05 },
      { name: "Euro 4", value: 3.87 },
      { name: "Euro 5", value: 3.87 },
      { name: "Euro 6", value: 3.87 },
    ],
  },
  {
    name: "Marche",
    below100KW: [
      { name: "Euro 0", value: 3.0 },
      { name: "Euro 1", value: 2.9 },
      { name: "Euro 2", value: 2.8 },
      { name: "Euro 3", value: 2.7 },
      { name: "Euro 4", value: 2.58 },
      { name: "Euro 5", value: 2.58 },
      { name: "Euro 6", value: 2.58 },
    ],
    above100KW: [
      { name: "Euro 0", value: 4.5 },
      { name: "Euro 1", value: 4.35 },
      { name: "Euro 2", value: 4.2 },
      { name: "Euro 3", value: 4.05 },
      { name: "Euro 4", value: 3.87 },
      { name: "Euro 5", value: 3.87 },
      { name: "Euro 6", value: 3.87 },
    ],
  },
  {
    name: "Molise",
    below100KW: [
      { name: "Euro 0", value: 3.53 },
      { name: "Euro 1", value: 3.38 },
      { name: "Euro 2", value: 3.24 },
      { name: "Euro 3", value: 3.09 },
      { name: "Euro 4", value: 2.76 },
      { name: "Euro 5", value: 2.76 },
      { name: "Euro 6", value: 2.76 },
    ],
    above100KW: [
      { name: "Euro 0", value: 5.3 },
      { name: "Euro 1", value: 5.07 },
      { name: "Euro 2", value: 4.85 },
      { name: "Euro 3", value: 4.63 },
      { name: "Euro 4", value: 4.14 },
      { name: "Euro 5", value: 4.14 },
      { name: "Euro 6", value: 4.14 },
    ],
  },
  {
    name: "Prov. Aut. Bolzano",
    below100KW: [
      { name: "Euro 0", value: 2.7 },
      { name: "Euro 1", value: 2.61 },
      { name: "Euro 2", value: 2.52 },
      { name: "Euro 3", value: 2.43 },
      { name: "Euro 4", value: 2.32 },
      { name: "Euro 5", value: 2.09 },
      { name: "Euro 6", value: 1.96 },
    ],
    above100KW: [
      { name: "Euro 0", value: 4.05 },
      { name: "Euro 1", value: 3.92 },
      { name: "Euro 2", value: 3.78 },
      { name: "Euro 3", value: 3.65 },
      { name: "Euro 4", value: 3.48 },
      { name: "Euro 5", value: 3.13 },
      { name: "Euro 6", value: 2.94 },
    ],
  },
  {
    name: "Prov. Aut. Trento",
    below100KW: [
      { name: "Euro 0", value: 3.0 },
      { name: "Euro 1", value: 2.9 },
      { name: "Euro 2", value: 2.8 },
      { name: "Euro 3", value: 2.7 },
      { name: "Euro 4", value: 2.58 },
      { name: "Euro 5", value: 2.06 },
      { name: "Euro 6", value: 1.96 },
    ],
    above100KW: [
      { name: "Euro 0", value: 4.5 },
      { name: "Euro 1", value: 4.35 },
      { name: "Euro 2", value: 4.2 },
      { name: "Euro 3", value: 4.05 },
      { name: "Euro 4", value: 3.87 },
      { name: "Euro 5", value: 3.1 },
      { name: "Euro 6", value: 2.95 },
    ],
  },
  {
    name: "Puglia",
    below100KW: [
      { name: "Euro 0", value: 3.0 },
      { name: "Euro 1", value: 2.9 },
      { name: "Euro 2", value: 2.8 },
      { name: "Euro 3", value: 2.7 },
      { name: "Euro 4", value: 2.58 },
      { name: "Euro 5", value: 2.58 },
      { name: "Euro 6", value: 2.58 },
    ],
    above100KW: [
      { name: "Euro 0", value: 4.5 },
      { name: "Euro 1", value: 4.35 },
      { name: "Euro 2", value: 4.2 },
      { name: "Euro 3", value: 4.05 },
      { name: "Euro 4", value: 3.87 },
      { name: "Euro 5", value: 3.87 },
      { name: "Euro 6", value: 3.87 },
    ],
  },
  {
    name: "Sardegna",
    below100KW: [
      { name: "Euro 0", value: 3.0 },
      { name: "Euro 1", value: 2.9 },
      { name: "Euro 2", value: 2.8 },
      { name: "Euro 3", value: 2.7 },
      { name: "Euro 4", value: 2.58 },
      { name: "Euro 5", value: 2.58 },
      { name: "Euro 6", value: 2.58 },
    ],
    above100KW: [
      { name: "Euro 0", value: 4.5 },
      { name: "Euro 1", value: 4.35 },
      { name: "Euro 2", value: 4.2 },
      { name: "Euro 3", value: 4.05 },
      { name: "Euro 4", value: 3.87 },
      { name: "Euro 5", value: 3.87 },
      { name: "Euro 6", value: 3.87 },
    ],
  },
  {
    name: "Sicilia",
    below100KW: [
      { name: "Euro 0", value: 3.0 },
      { name: "Euro 1", value: 2.9 },
      { name: "Euro 2", value: 2.8 },
      { name: "Euro 3", value: 2.7 },
      { name: "Euro 4", value: 2.58 },
      { name: "Euro 5", value: 2.58 },
      { name: "Euro 6", value: 2.58 },
    ],
    above100KW: [
      { name: "Euro 0", value: 4.5 },
      { name: "Euro 1", value: 4.35 },
      { name: "Euro 2", value: 4.2 },
      { name: "Euro 3", value: 4.05 },
      { name: "Euro 4", value: 3.87 },
      { name: "Euro 5", value: 3.87 },
      { name: "Euro 6", value: 3.87 },
    ],
  },
  {
    name: "Umbria",
    below100KW: [
      { name: "Euro 0", value: 3.0 },
      { name: "Euro 1", value: 2.9 },
      { name: "Euro 2", value: 2.8 },
      { name: "Euro 3", value: 2.7 },
      { name: "Euro 4", value: 2.58 },
      { name: "Euro 5", value: 2.58 },
      { name: "Euro 6", value: 2.58 },
    ],
    above100KW: [
      { name: "Euro 0", value: 4.5 },
      { name: "Euro 1", value: 4.35 },
      { name: "Euro 2", value: 4.2 },
      { name: "Euro 3", value: 4.05 },
      { name: "Euro 4", value: 3.87 },
      { name: "Euro 5", value: 3.87 },
      { name: "Euro 6", value: 3.87 },
    ],
  },
  {
    name: "Valle d'Aosta",
    below100KW: [
      { name: "Euro 0", value: 3.0 },
      { name: "Euro 1", value: 2.9 },
      { name: "Euro 2", value: 2.8 },
      { name: "Euro 3", value: 2.7 },
      { name: "Euro 4", value: 2.58 },
      { name: "Euro 5", value: 2.58 },
      { name: "Euro 6", value: 2.58 },
    ],
    above100KW: [
      { name: "Euro 0", value: 4.5 },
      { name: "Euro 1", value: 4.35 },
      { name: "Euro 2", value: 4.2 },
      { name: "Euro 3", value: 4.05 },
      { name: "Euro 4", value: 3.87 },
      { name: "Euro 5", value: 3.87 },
      { name: "Euro 6", value: 3.87 },
    ],
  },
  {
    name: "Veneto",
    below100KW: [
      { name: "Euro 0", value: 3.3 },
      { name: "Euro 1", value: 3.19 },
      { name: "Euro 2", value: 3.08 },
      { name: "Euro 3", value: 2.97 },
      { name: "Euro 4", value: 2.84 },
      { name: "Euro 5", value: 2.84 },
      { name: "Euro 6", value: 2.58 },
    ],
    above100KW: [
      { name: "Euro 0", value: 4.95 },
      { name: "Euro 1", value: 4.79 },
      { name: "Euro 2", value: 4.62 },
      { name: "Euro 3", value: 4.46 },
      { name: "Euro 4", value: 4.26 },
      { name: "Euro 5", value: 4.26 },
      { name: "Euro 6", value: 3.87 },
    ],
  },
  {
    name: "Toscana",
    below100KW: [
      { name: "Euro 0", value: 3.47 },
      { name: "Euro 1", value: 3.35 },
      { name: "Euro 2", value: 3.23 },
      { name: "Euro 3", value: 3.12 },
      { name: "Euro 4", value: 2.71 },
      { name: "Euro 5", value: 2.71 },
      { name: "Euro 6", value: 2.71 },
    ],
    above100KW: [
      { name: "Euro 0", value: 5.45 },
      { name: "Euro 1", value: 5.37 },
      { name: "Euro 2", value: 5.08 },
      { name: "Euro 3", value: 4.91 },
      { name: "Euro 4", value: 4.26 },
      { name: "Euro 5", value: 4.26 },
      { name: "Euro 6", value: 4.26 },
    ],
  },
  {
    name: "Piemonte",
    below54KW: [
      { name: "Euro 0", value: 3 },
      { name: "Euro 1", value: 2.9 },
      { name: "Euro 2", value: 2.8 },
      { name: "Euro 3", value: 2.7 },
      { name: "Euro 4", value: 2.58 },
      { name: "Euro 5", value: 2.58 },
      { name: "Euro 6", value: 2.58 },
    ],
    above54KW: [
      { name: "Euro 0", value: 3.18 },
      { name: "Euro 1", value: 3.07 },
      { name: "Euro 2", value: 2.97 },
      { name: "Euro 3", value: 2.86 },
      { name: "Euro 4", value: 2.73 },
      { name: "Euro 5", value: 2.73 },
      { name: "Euro 6", value: 2.73 },
    ],
    first100KWAbove100KW: [
      { name: "Euro 0", value: 3.24 },
      { name: "Euro 1", value: 3.13 },
      { name: "Euro 2", value: 3.02 },
      { name: "Euro 3", value: 2.92 },
      { name: "Euro 4", value: 2.79 },
      { name: "Euro 5", value: 2.79 },
      { name: "Euro 6", value: 2.79 },
    ],
    eachKWAfter100KWBelow131KW: [
      { name: "Euro 0", value: 4.86 },
      { name: "Euro 1", value: 4.7 },
      { name: "Euro 2", value: 4.54 },
      { name: "Euro 3", value: 4.37 },
      { name: "Euro 4", value: 4.18 },
      { name: "Euro 5", value: 4.18 },
      { name: "Euro 6", value: 4.18 },
    ],
    first100KWAbove131KW: [
      { name: "Euro 0", value: 3.3 },
      { name: "Euro 1", value: 3.19 },
      { name: "Euro 2", value: 3.08 },
      { name: "Euro 3", value: 2.97 },
      { name: "Euro 4", value: 2.84 },
      { name: "Euro 5", value: 2.84 },
      { name: "Euro 6", value: 2.84 },
    ],
    eachKWAfter100KWAbove131KW: [
      { name: "Euro 0", value: 4.95 },
      { name: "Euro 1", value: 4.79 },
      { name: "Euro 2", value: 4.62 },
      { name: "Euro 3", value: 4.46 },
      { name: "Euro 4", value: 4.26 },
      { name: "Euro 5", value: 4.26 },
      { name: "Euro 6", value: 4.26 },
    ],
  },
];
