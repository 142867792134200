import React, { Dispatch, SetStateAction } from "react";
import { styles } from "../BolloCalculator.style";

interface SelectValueProps {
  setSelectValue: Dispatch<SetStateAction<string>>;
  table: { name: string }[];
}

const SelectValue: React.FC<SelectValueProps> = ({ setSelectValue, table = [] }) => {
  return (
    <>
      {table.map((item) => (
        <div key={item.name} onClick={() => setSelectValue(item.name)} css={styles.option()}>
          {item.name}
        </div>
      ))}
    </>
  );
};
export default SelectValue;
