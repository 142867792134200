import { css } from "@emotion/react";
import { colors, fontSizes, letterSpacings, mediaQuery, spacing, lineHeights } from "../../core/styles";

export const styles = {
  root: (isBlue: boolean) =>
    css({
      maxWidth: "894px",
      margin: "0 auto",
      background: isBlue ? colors.backgroundBlue.hex : "transparent",
      textAlign: "center",
    }),
  wrapper: (vinWithFox: boolean) =>
    css({
      maxWidth: "528px",
      margin: vinWithFox ? `89px auto ${spacing.baseUnitMobile3}` : `0 auto ${spacing.baseUnitMobile3}`,
      position: "relative",
    }),
  mainHeading: (hasBlueBackground: boolean) =>
    css({
      color: hasBlueBackground ? colors.white.hex : colors.headlineGrey.hex,
    }),
  secondHeading: () =>
    css({
      color: colors.white.hex,
      marginBottom: spacing.baseUnitMobile3,
      fontSize: fontSizes.heading.H1.mobile,
      lineHeight: lineHeights.heading.H1.mobile,
      [mediaQuery.desktopOrLarger]: {
        fontSize: fontSizes.heading.H3.desktop,
        lineHeight: lineHeights.heading.H3.desktop,
      },
    }),
  searchFoxImg: () =>
    css({
      width: "124px",
      position: "absolute",
      top: "10px",
      left: "50%",
      transform: "translate(-50%, -100%)",
    }),
  partnerLogo: () =>
    css({
      maxWidth: "160px",
      maxHeight: "100px",
      margin: spacing.baseUnitMobile1,
    }),
  modal: () =>
    css({
      color: colors.white.hex,
      margin: "0 auto",
      width: "fit-content",
      display: "block",
      cursor: "pointer",
      textAlign: "center",
      fontWeight: "bold",
      fontsize: fontSizes.body.mouse,
      letterSpacing: letterSpacings.body.mouse,
      lineHeight: lineHeights.body.mouse,
      textDecoration: "none",
      [mediaQuery.desktopOrLarger]: {
        lineHeight: lineHeights.body.horse,
      },
      "&:hover": {
        textDecoration: "underline",
      },
    }),
};
