import React, { useState } from "react";
import { Modal } from "@carfax/web-ui/components/Modal";
import { useTranslation } from "app";
import { styles } from "./HowDoesItWorkModal.style";
import HowDoesItWorkModalContent from "../HowDoesItWorkModalContent";

interface Props {
  modalLabel?: string;
}

const HowDoesItWorkModal: React.FC<Props> = ({ modalLabel }) => {
  const { t } = useTranslation(["common"]);
  const [modalOpen, setModalOpen] = useState(false);

  const onModalTriggerClick = (event: any) => {
    event.preventDefault();
    setModalOpen(true);
  };

  return (
    <>
      <a href="" css={styles.modalTrigger} onClick={onModalTriggerClick}>
        {modalLabel}
      </a>
      <Modal isOpen={modalOpen} onClose={() => setModalOpen(false)} closeText={t("modal.close")}>
        <HowDoesItWorkModalContent />
      </Modal>
    </>
  );
};

export default HowDoesItWorkModal;
