import React, { useMemo, useState } from "react";
import { Input } from "../../../../elements/InputNew/Input";
import { styles } from "./VinCheckInput.style";

export enum InputStatus {
  DEFAULT = "default",
  SUCCESS = "success",
  LOADING = "loading",
  ERROR = "error",
}
export interface VinCheckProps {
  vinCheckLabelText: string;
  inputStatus: string;
  vinCheckErrorMessage: string;
  message: string;
  hasError: boolean;
  testId: string;
  handleVinCheck: (vinNumber: string) => Promise<void>;
}

export const VinCheckInput: React.FC<VinCheckProps> = ({
  vinCheckLabelText,
  inputStatus,
  hasError,
  vinCheckErrorMessage,
  message,
  testId,
  handleVinCheck,
}) => {
  const vinCheckError = inputStatus === InputStatus.ERROR;
  const errorMessage = vinCheckError ? vinCheckErrorMessage : hasError && message;
  const [vinNumber, setVinNumber] = useState("");

  const handleChange = (e) => {
    setVinNumber(e.target.value);
  };
  const animatedIcon = useMemo(() => {
    switch (inputStatus) {
      case InputStatus.DEFAULT:
        return null;
      case InputStatus.LOADING:
        return <div data-testid="icon-loading" css={styles.loadingIcon()} />;
      case InputStatus.SUCCESS:
        return <div data-testid="icon-success" css={styles.successIcon()} />;

      default:
        return null;
    }
  }, [inputStatus]);

  return (
    <Input
      testId={testId}
      labelText={vinCheckLabelText}
      onChange={handleChange}
      onBlur={() => vinNumber !== "" && handleVinCheck(vinNumber)}
      type="text"
      min="1"
      max="999"
      customEndIcon={animatedIcon}
      hasError={hasError}
      message={errorMessage}
    />
  );
};
