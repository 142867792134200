import { useReducer, useEffect } from "react";
import { initialState, reducer } from "./errorHandlerReducer";

export const useErrorHandler = (
  regionName: string,
  euroNorm: { name: string; value: number },
  enginePower: number,
  regionPlaceholder: string,
  euroNormPlaceholderValue: string,
  euroNormPlaceholder: string,
  isVinSearchData: boolean
) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const handleInputError = (inputValue, placeholderValue, actionType) => {
    if (!inputValue || inputValue === placeholderValue) {
      dispatch({ type: actionType, payload: true });
      return true;
    } else {
      dispatch({ type: actionType, payload: false });
      return false;
    }
  };

  const resetErrors = () => {
    dispatch({ type: "RESET_ERRORS" });
  };

  useEffect(() => {
    if (state.validationPerformed) {
      validateFields();
    }
  }, [regionName, euroNorm?.name, enginePower]);

  const validateVinSearchFields = (dataLayerEvent?: (options) => void) => {
    const vinSearchError = handleInputError(isVinSearchData, 0, "SET_VIN_CHECK_ERROR");
    const regionError = handleInputError(regionName, regionPlaceholder, "SET_REGION_VIN_SEARCH_ERROR");

    if (dataLayerEvent) {
      dataLayerEvent({ vinSearchError, regionError });
    }
  };

  const validateFields = (dataLayerEvent?: (options) => void) => {
    const regionError = handleInputError(regionName, regionPlaceholder, "SET_REGION_ERROR");
    const euroNormError = handleInputError(euroNormPlaceholderValue, euroNormPlaceholder, "SET_EURO_NORM_ERROR");
    const enginePowerError = handleInputError(enginePower, 0, "SET_ENGINE_POWER_ERROR");

    if (dataLayerEvent) {
      dataLayerEvent({ euroNormError, enginePowerError, regionError });
    }
  };

  return {
    check: {
      regionHasError: state.regionHasError,
      regionVinSearchHasError: state.regionVinSearchHasError,
      enginePowerHasError: state.enginePowerHasError,
      euroNormHasError: state.euroNormHasError,
      vinCheckHasError: state.vinCheckHasError,
    },
    handle: {
      validateFields,
      validateVinSearchFields,
      resetErrors,
      setValidationPerformed: (value: boolean) => dispatch({ type: "SET_VALIDATION_PERFORMED", payload: value }),
    },
  };
};
