import React, { useEffect, useState } from "react";
import { ModuleLandingPageVoucherEntry } from "../../../contentful/PageModularModule";
import { VoucherEntryHeader } from "@carfax/web-ui/components/VoucherEntryHeader";
import RouterService from "../../Routing/utils/router-service";
import { Routes } from "../../../utils/config/routes";
import { useCheckVoucherCode } from "../../PaymentFlow/Payment/VoucherField/utils/useCheckVoucherCode";
import { useTranslation } from "../../../app";

export interface ILandingPageVoucherEntry {
  module: ModuleLandingPageVoucherEntry;
}

const LandingPageVoucherEntry: React.FC<ILandingPageVoucherEntry> = ({ module }) => {
  const { t } = useTranslation(["common", "forms"]);
  const [voucherValue, setVoucherValue] = useState("");
  const { checkVoucher, voucherCheckPending, voucherErrorMsg, validated } = useCheckVoucherCode();

  const onClickCheckVoucher = async (voucherCode) => {
    checkVoucher(voucherCode, module.packageType);
  };

  useEffect(() => {
    if (validated) {
      RouterService.routeWithQuery(Routes.PARTNER_PACKAGES, { partnerName: module.packageType });
    }
  }, [validated]);

  return (
    <div>
      <VoucherEntryHeader
        headline={module.headline}
        description={module.description}
        ctaText={module.ctaText}
        image={module.picture}
        onVoucherEntry={() => onClickCheckVoucher(voucherValue)}
        inputProps={{
          submitButtonTestId: "submit-voucher-btn",
          inputName: "voucher-input",
          placeholderMessage: `${t("common:voucherCodeInput.form.placeholder")}`,
          onChange: (event) => setVoucherValue(event.target.value),
          onBlur: (event) => setVoucherValue(event.target.value),
          error: voucherErrorMsg ? t("forms:" + voucherErrorMsg) : "",
          validated: validated,
          voucherCheckPending: voucherCheckPending,
          onSubmit: () => onClickCheckVoucher(voucherValue),
        }}
      />
    </div>
  );
};

export default LandingPageVoucherEntry;
