import { css } from "@emotion/css";

export const styles = {
  root: (width?: number, aspectRatio?: string) =>
    css({
      width: "100%",
      aspectRatio: aspectRatio || "auto",
      height: "auto",
      maxWidth: width ? `${width}px` : "initial",
    }),
  image: (aspectRatio: string) =>
    css({
      display: "block",
      maxWidth: "100%",
      maxHeight: "100%",
      aspectRatio: aspectRatio,
      height: "auto",
      width: "100%",
    }),
};
