import { mediaQuery, spacing } from "@carfax/web-ui/core";
import { css } from "app";

export const styles = {
  modalHeading: () =>
    css({
      textAlign: "center",
      [mediaQuery.desktopOrLarger]: {
        marginTop: spacing.baseUnitMobile1,
      },
    }),
  modalTextWrapper: () =>
    css({
      h1: {
        margin: `0 auto ${spacing.baseUnitMobile3}`,
      },
    }),
  advantagesHeader: () => css({ marginBottom: 0 }),
  advantagesList: () =>
    css({
      listStyleType: "none",
      marginBottom: spacing.baseUnitMobile4,
      "& > li::before": {
        content: '"- "',
      },
    }),
};
