import { taxConfig } from "../../taxConfig";
import { EnginePower } from "../BolloCalculator";
import { bolloCalculatorUtil } from "./bolloCalculatorUtils";
import { useMemo } from "react";

export const usePiemonteRegionCalculator = (enginePower: number, regionName: string) => {
  const euroNormForRegion = bolloCalculatorUtil.getValueFromArray(taxConfig, regionName);

  const isPiemonteRegion: boolean = useMemo(() => regionName === "Piemonte", [regionName]);

  const piemonteCalculator = () => {
    if (enginePower < EnginePower.EQUAL_51KW) {
      return euroNormForRegion.below54KW;
    } else if (enginePower >= EnginePower.EQUAL_51KW && enginePower <= EnginePower.EQUAL_100KW) {
      return euroNormForRegion.above54KW;
    } else if (enginePower > EnginePower.EQUAL_100KW && enginePower < EnginePower.EQUAL_131KW) {
      return euroNormForRegion.first100KWAbove100KW;
    } else if (enginePower > EnginePower.EQUAL_131KW) {
      return euroNormForRegion.first100KWAbove131KW;
    }
    return euroNormForRegion.first100KWAbove131KW;
  };

  const additionalTaxTable = () => {
    if (enginePower > EnginePower.EQUAL_100KW && enginePower < EnginePower.EQUAL_131KW) {
      return euroNormForRegion.eachKWAfter100KWBelow131KW;
    } else if (enginePower >= EnginePower.EQUAL_131KW) {
      return euroNormForRegion.eachKWAfter100KWAbove131KW;
    }
    return [];
  };

  const maxEnginePower100 = enginePower > EnginePower.EQUAL_100KW ? EnginePower.EQUAL_100KW : enginePower;

  return { piemonteCalculator, isPiemonteRegion, additionalTaxTable, maxEnginePower100 };
};
