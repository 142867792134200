import { colors, fontSizes, letterSpacings, lineHeights, mediaQuery, spacing } from "../../core/styles";
import { css } from "@emotion/react";

export const styles = {
  root: () =>
    css({
      position: "relative",
      width: "100vw",
      left: "50%",
      marginLeft: "-50vw",
      textAlign: "center",
      overflow: "hidden",
      paddingBottom: "-100px",
      background: `linear-gradient(180deg, ${colors.white.hex} 0%, ${colors.textGrey.hex} 100%)`,
      [mediaQuery.tabletOrLarger]: {
        textAlign: "left",
      },
    }),
  contentWrapper: () =>
    css({
      [mediaQuery.tabletOrLarger]: {
        minHeight: "240px",
      },
    }),
  title: () =>
    css({
      marginBottom: spacing.baseUnitMobile2,
    }),
  cta: () =>
    css({
      marginBottom: spacing.baseUnitMobile3,
      [mediaQuery.smallerThanTablet]: {
        marginTop: spacing.baseUnitMobile1,
      },
      padding: `${spacing.baseUnitMobile2} ${spacing.baseUnitMobile8}`,
    }),
  textWrapper: () =>
    css({
      marginBottom: spacing.baseUnitMobile3,
      p: {
        fontSize: fontSizes.body.horse,
        lineHeight: lineHeights.body.horse,
        letterSpacing: letterSpacings.body.horse,
        marginBottom: 0,
      },
      b: {
        fontWeight: 700,
      },
    }),
};
