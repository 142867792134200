import { css } from "@emotion/react";
import { colors, spacing, fontSizes, mediaQuery } from "../../core";

export const styles = {
  root: () =>
    css({
      maxWidth: "800px",
      margin: "0 auto",
      padding: `0 ${spacing.baseUnitMobile2}`,
      color: colors.headlineGrey.hex,
      fontSize: fontSizes.body.horse,
      letterSpacing: "0.5px",
      h1: {
        textAlign: "center",
        marginBottom: spacing.baseUnitMobile3,
        [mediaQuery.desktopOrLarger]: {
          maxWidth: "1200px",
        },
      },
    }),
  partnerLogo: () =>
    css({
      display: "block",
      maxHeight: "100px",
      maxWidth: "160px",
      width: "100%",
      margin: "0 auto",
      marginBottom: spacing.baseUnitMobile2,
      [mediaQuery.desktopOrLarger]: {
        marginBottom: spacing.baseUnitMobile1,
      },
    }),
};
