import { css } from "@emotion/react";
import { colors, fontSizes, letterSpacings, lineHeights, mediaQuery, spacing } from "../../core";

export const styles = {
  root: () =>
    css({
      display: "flex",
      flexWrap: "wrap",
      flexDirection: "row",
      justifyContent: "center",
      padding: spacing.baseUnitMobile2,
      color: colors.headlineGrey.hex,
      h1: {
        marginBottom: spacing.baseUnitMobile2,
      },
      [mediaQuery.desktopOrLarger]: {
        gap: spacing.baseUnitMobile9,
        padding: 0,
      },
    }),
  textWrapper: () =>
    css({
      width: "530px",
    }),
  subtitle: () =>
    css({
      fontSize: fontSizes.body.horse,
      lineHeight: lineHeights.body.horse,
      letterSpacing: letterSpacings.body.horse,
      marginBottom: spacing.baseUnitMobile3,
    }),
  information: () =>
    css({
      display: "none",
      [mediaQuery.desktopOrLarger]: {
        display: "block",
        fontSize: fontSizes.body.mouse,
        lineHeight: lineHeights.body.mouse,
        letterSpacing: letterSpacings.body.mouse,
      },
    }),
  informationMobile: () =>
    css({
      fontSize: fontSizes.body.mouse,
      lineHeight: lineHeights.body.mouse,
      letterSpacing: letterSpacings.body.mouse,
      marginTop: spacing.baseUnitMobile2,
      maxWidth: "528px",
      [mediaQuery.desktopOrLarger]: {
        display: "none",
      },
    }),
  bolloWrapper: () =>
    css({
      width: "100%",
      maxWidth: "528px",
      [mediaQuery.desktopOrLarger]: {
        height: "372px",
      },
    }),
  tabWrapper: () =>
    css({
      display: "flex",
      flexDirection: "row",
      justifyContent: "center",
      gap: spacing.baseUnitMobile1,
      marginBottom: spacing.baseUnitMobile2,
    }),
};
