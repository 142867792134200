import React from "react";
import { LazyImage } from "react-lazy-images";
import { styles } from "./Image.style";

interface IProps {
  image?: IImage;
  loadEagerly?: boolean;
}
interface IImage {
  url: string;
  width?: number;
  height?: number;
  title?: string;
}

export const calcAspectForImage = (image: IImage): string => {
  return image?.width && image?.height ? Number(image.width / image.height).toPrecision(3) : "auto";
};

export const Image: React.FC<IProps> = ({ image, loadEagerly = false }) => {
  const aspect = calcAspectForImage(image);

  return (
    <div css={styles.root(image?.width)}>
      <div>
        {image && image.url && (
          <LazyImage
            src={`${image.url}?fm=webp&w=200`}
            srcSet={`${image.url}?fm=webp 1200w,
             ${image.url}?fm=webp&w=200 200w,
             ${image.url}?fm=webp&w=400 400w,
             ${image.url}?fm=webp&w=800 800w,
             ${image.url}?fm=webp&w=1024 1024w`}
            alt={image.title}
            loadEagerly={loadEagerly}
            placeholder={({ ref }) => {
              return <div style={{ display: "block", aspectRatio: aspect, width: "100%", height: "auto" }} ref={ref} />;
            }}
            actual={({ imageProps }) => (
              <picture>
                <source srcSet={`${imageProps.srcSet}`} type="image/webp" />
                <img
                  alt={image.title}
                  title={image.title}
                  className={styles.image(aspect)}
                  src={imageProps.src.replace("fm=webp", "")}
                />
              </picture>
            )}
          />
        )}
      </div>
    </div>
  );
};
